import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";

import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";
/*Logo */
import Logo from "../../assets/images/icons/LogoFooter";
/*icons */
import IconLocation from "../../assets/images/icons/Location";
import IconMail from "../../assets/images/icons/Email";
import IconCall from "../../assets/images/icons/Call";
import IconInstagram from "../../assets/images/icons/social/Instagram";
import IconFacebook from "../../assets/images/icons/social/Facebook";
import IconTiktok from "../../assets/images/icons/social/Tiktok";

export default function Footer({ shoppingCart }) {
  const {
    // businessData,
    businessData: {
      // urlImage,
      // tradeName,
      address,
      email,
      phone,
      config,
      social,
    },
  } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  const { footerColor, lblTextColor } =
    config && config.style && config.style.digitalMenu
      ? config.style.digitalMenu
      : "";

  return (
    <ContainerGeneral
      footerColor={footerColor}
      lblTextColor={lblTextColor}
      shoppingCart={shoppingCart}
    >
      <div className="container">
        <Section1>
          <div className="left">
            {/* <Description>
              {urlImage && (<LogoBusiness logo={urlImage}/>)}
              {tradeName && (<p>{tradeName}</p>)}
            </Description> */}
            {/* <Menu>
              <h4>Feature</h4>
              <ul>
                <Link>
                  <li>Menú</li>
                </Link>
                <Link>
                  <li>Contacto</li>
                </Link>
              </ul>
            </Menu> */}
            <Contact id="footer_contact">
              {(address || email || phone) && <h4>Contacto</h4>}
              {address && (
                <div className="item">
                  {/* <img src={iconLocation} alt="Dirección"/> */}
                  <IconLocation color={lblTextColor} />
                  <p>{address}</p>
                </div>
              )}
              {email && (
                <div className="item">
                  {/* <img src={IconMail} alt="Email"/> */}
                  <IconMail color={lblTextColor} />
                  <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                    {email}
                  </a>
                </div>
              )}
              {phone && (
                <div className="item">
                  {/* <img src={IconCall} alt="Teléfono"/> */}
                  <IconCall color={lblTextColor} />
                  <a
                    href={`https://api.whatsapp.com/send?phone=${phone}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {phone}
                  </a>
                </div>
              )}
            </Contact>
          </div>
          <div className="right">
            <Social color={lblTextColor}>
              {social?.instagram || social?.facebook || social?.tiktok ? (
                <>
                  <h4>Síguenos</h4>
                  <div className="icons">
                    {social?.instagram && (
                      <a
                        title="Instagram"
                        href={`https://www.instagram.com/${social?.instagram}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconInstagram color={lblTextColor} />
                        <p>{social?.instagram}</p>
                      </a>
                    )}
                    {social?.facebook && (
                      <a
                        title="Facebook"
                        href={`https://es-la.facebook.com/${social?.facebook}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconFacebook color={lblTextColor} />
                        <p>{social?.facebook}</p>
                      </a>
                    )}
                    {social?.tiktok && (
                      <a
                        title="Tiktok"
                        href={`https://www.tiktok.com/@${social?.tiktok}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconTiktok color={lblTextColor} />
                        <p>{social?.tiktok}</p>
                      </a>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </Social>
          </div>
        </Section1>
        <Section2>
          {/* <p>©Copyright Miss Ensaladas</p> */}
          <a
            className="madeBy"
            href="https://www.pirpos.com/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Hecho por</p>
            {/* <img src={Logo} alt="logo Pirpos" /> */}
            <Logo color={lblTextColor} />
          </a>
          {/* <div className="madeBy">
          </div> */}
        </Section2>
      </div>
    </ContainerGeneral>
  );
}

const ContainerGeneral = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(props) =>
    props.footerColor ? props.footerColor : "var(--color-Back-Yelow)"};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
  font-size: 0.9rem;
  .container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1023px) {
      padding-bottom: ${(props) => (props.shoppingCart ? "20px" : "80px")};
    }
  }
`;

const Section1 = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  .left,
  .right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    @media (max-width: 1023px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`;

// const Description = styled.div`
//   width: 50%;
//   height: 100%;
//   padding: 40px 20px;
//   display: flex;
//   flex-direction: column;
//   @media (max-width: 767px) {
//     width: 100%;
//     padding: 20px 40px;
//   }
// `;

// const LogoBusiness = styled.div`
//   width: 150px;
//   height: 50px;
//   margin-bottom: 1rem;
//   background-image: ${(props) => `url(${props.logo})`};
//   background-repeat: no-repeat;
//   background-position: left;
//   background-size: contain;
// `;

// const Menu = styled.div`
//   width: 50%;
//   height: 100%;
//   padding: 40px 20px;
//   display: none;
//   @media (max-width: 767px) {
//     width: 100%;
//     padding: 20px 40px;
//   }
//   > h4 {
//     margin-bottom: 1rem;
//   }
//   > ul {
//     list-style-type: none;
//     > a {
//       color: var(--color-Gray);
//       &:hover {
//         color: var(--color-Green);
//       }
//     }
//   }
// `;

const Contact = styled.div`
  width: 50%;
  padding: 40px 40px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px 40px;
  }
  > h4 {
    margin-bottom: 1rem;
  }
  & .item:last-child {
    margin-bottom: 0;
  }
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    > img {
      margin-right: 1rem;
    }
    > a,
    p {
      margin-left: 1rem;
      color: inherit;
    }
  }
`;

const Social = styled.div`
  width: 50%;
  padding: 40px 20px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px 40px;
  }
  > H4 {
    margin-bottom: 1rem;
  }
  .icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & a:last-child {
      margin-bottom: 0;
    }
    > a {
      display: flex;
      margin-bottom: 0.8rem;
      align-items: center;
      > svg {
        margin-right: 0.5rem;
      }
      > p {
        color: ${(props) => (props.color ? props.color : "var(--color-Gray)")};
      }
    }
  }
`;

const Section2 = styled.div`
  width: 100%;
  height: 30%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .madeBy {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    > p {
      margin-right: 0.5rem;
    }
  }
`;
