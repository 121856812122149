import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import Product from "./Product";
import NotMenu from "../../assets/images/icons/NotMenu";

const Message = ({
  menuBusiness,
  btnColor,
  productsCount,
  filteredProducts,
}) => {
  return (
    <ContainerMessage>
      {productsCount && !filteredProducts ? (
        <h3>Productos no disponibles....</h3>
      ) : menuBusiness ? (
        <h3>Cargando ...</h3>
      ) : (
        <>
          <NotMenu color={btnColor} />
          <h3>No hay un menú configurado.</h3>
        </>
      )}
    </ContainerMessage>
  );
};

export default function CategoryProducts({
  products = [],
  menuBusiness,
  btnColor,
  stateBanner,
}) {
  const { id: businessId } = useParams();

  // const filteredProducts = products.filter(
  //   (p) => p.internalId?.isActive === true
  // );

  const filteredProducts = [];

  for (let product of products) {
    if (product.internalId?.isActive) {
      const productAltered = { ...product };
      const mainStock = productAltered.internalId.locationsStock?.find(
        (stock) => stock?.locationStock?.business === businessId
      );
      productAltered.internalId.locationsStock = [mainStock];

      filteredProducts.push(productAltered);
    }
  }

  return (
    <>
      {filteredProducts.length === 0 ? (
        <Message
          menuBusiness={menuBusiness}
          productsCount={products.length}
          filteredProducts={filteredProducts.length}
          btnColor={btnColor}
        />
      ) : (
        <ContainerGeneral stateBanner={stateBanner}>
          {filteredProducts.map((product) => {
            return <Product key={product.internalId._id} product={product} />;
          })}
        </ContainerGeneral>
      )}
    </>
  );
}

const ContainerGeneral = styled.div`
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex: 1;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  @media (max-width: 1023px) {
    padding: 0 20px 20px;
    margin-top: ${(props) => (props.stateBanner ? "10px" : "140px")};
  }
  @media (max-width: 1023px) and (orientation: landscape) {
    margin-top: ${(props) => (props.stateBanner ? "10px" : "0px")};
  }
  @media (max-width: 767px) {
    margin-top: ${(props) => (props.stateBanner ? "10px" : "140px")};
  }
  @media (max-width: 767px) and (orientation: landscape) {
    margin-top: ${(props) => (props.stateBanner ? "10px" : "0px")};
  }
`;

const ContainerMessage = styled.div`
  width: 100%;
  height: calc(100vh - 408px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > svg {
    opacity: 0.4;
  }
  > h3 {
    margin-top: 1rem;
  }
  @media (max-width: 767px) {
    height: calc(100vh - 100px);
    > h3 {
      font-size: 0.8rem;
    }
  }
`;
