import React from "react";
import ReactLoading from "react-loading";

/**
 * LOADING TYPES
 * blank
 * balls
 * bars
 * bubbles
 * cubes
 * cylon
 * spin
 * spinningBubbles
 * spokes
 */

const Loading = ({ type, color, height, width }) => (
  <ReactLoading
    type={type ?? "spin"}
    color={color ?? "black"}
    height={height ?? "50%"}
    width={width ?? "50%"}
  />
);

export { Loading };
