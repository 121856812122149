import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CarMobile = ({
  quantity = 0,
  total = 0,
  configCurrency,
  business,
  closeModal,
  session,
  urlExtension,
  btnColor,
  backgroundColor,
}) => {
  return (
    <ContainerGeneral btnColor={btnColor} backgroundColor={backgroundColor}>
      {session ? (
        <Link
          className="containerCar"
          to={`/shopping-cart/${business}${urlExtension}`}
        >
          <Counter>{quantity}</Counter>
          <p>Ver Carrito de Compras</p>
          <h4>
            {total.toLocaleString(configCurrency.bcp47, {
              style: "currency",
              currency: configCurrency.iso_4217,
            })}
          </h4>
        </Link>
      ) : (
        <div
          className="containerCar"
          onClick={() => {
            closeModal();
          }}
        >
          <Counter>{quantity}</Counter>
          <p>INICIAR/REGISTRARSE</p>
          <h4>
            {total.toLocaleString(configCurrency.bcp47, {
              style: "currency",
              currency: configCurrency.iso_4217,
            })}
          </h4>
        </div>
      )}
    </ContainerGeneral>
  );
};

export default CarMobile;

const ContainerGeneral = styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  padding: 10px 20px 20px;
  bottom: -1px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-White)"};
  display: none;
  @media (max-width: 1023px) {
    display: flex;
  }
  .containerCar {
    width: 100%;
    background-color: ${(props) =>
      props.btnColor ? props.btnColor : "var(--color-Green)"};
    border-radius: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    > p {
      font-size: 0.8rem;
      color: var(--color-White);
      padding: 0 10px;
    }
    > h4 {
      font-size: 0.9rem;
      color: var(--color-White);
    }
    :hover {
      cursor: pointer;
    }
  }
`;

const Counter = styled.div`
  padding: 5px 8px;
  border-radius: 5px;
  line-height: 1;
  background-color: var(--color-White);
`;
