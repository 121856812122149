import React from "react";
import styled from "styled-components";
import { useParams, Redirect } from "react-router-dom";
import axios from "axios";
import { Toaster } from "react-hot-toast";

import { MENU_SHOPPINGCART_CONTEXT } from "../context/MenuShoppingCartProvider";
import Form from "../components/shopping-car/Form";
import Head from "../components/shopping-car/Head";
import ProductCar from "../components/shopping-car/ProductCar";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import ModalRegister from "../components/shared/ModalRegister";

import {
  findProductAndSubProductInMenu,
  getCurrentStock,
  updateProductInShoppingCart,
} from "../components/shopping-car/validateShoppingCar";

export default function ShoppingCar() {
  // Menu && ShoppingCart context
  const {
    session,
    number,
    menu,
    shoppingCart,
    setShoppingCart,
    categorySelected,
    setCategorySelected,
    configCurrency,
    quantity = 0,
    subTotal = 0,
    configDeliveryCost = 0,
    businessData,
    configPayU,
    urlQueries,
    urlExtension,
    logOut,
    setMenu,
  } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  // Get business Id in menu's Url
  const { id } = useParams();
  const beforeBusinessInLS = window.localStorage.getItem("business");
  const beforeBusinessInUrl = beforeBusinessInLS
    ? JSON.parse(beforeBusinessInLS)
    : "";

  if (id !== beforeBusinessInUrl) {
    setShoppingCart({});
    window.localStorage.setItem("business", JSON.stringify(id));
  }

  // Array of shopping cart
  const DATA_PRODUCTS = [];
  for (let product in shoppingCart) {
    DATA_PRODUCTS.push(shoppingCart[product]);
  }

  /* ------------ Delivery form ------------ */
  const [deliveryFormState, setDeliveryFormState] = React.useState("delivery");

  const setDeliveryForm = (e) => {
    setDeliveryFormState(e.target.value);
  };

  // Total of order
  let total = subTotal + configDeliveryCost;
  const noDelivery = 0;
  if (
    urlQueries["type"] === "INSIDE_RESTAURANT" ||
    deliveryFormState === "inSite"
  ) {
    total = subTotal;
  }

  // dataForm of PayU's payment webCheckout integration
  const [payu, setPayu] = React.useState({});
  const [changeAmount, setChangeAmount] = React.useState(false);

  const getDataWebCheckoutPayu = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_PAYU_DATA_BASE_URL}/contactless/payu/infoForm/business/${id}?total=${total}`
      )
      .then((res) => {
        setPayu(res.data);
      })
      .catch((error) => console.log(error));
  };

  const [someHappyHour, setSomeHappyHour] = React.useState(false);

  const evaluateShoppingCart = (menu) => {
    let shoppingCartToSave = { ...shoppingCart };
    let happyHourProducts = 0;
    DATA_PRODUCTS.map((productInShoppingCart) => {
      const { productInMenu, subProductInMenu } =
        findProductAndSubProductInMenu(menu, productInShoppingCart);

      const currentStock = getCurrentStock(id, productInMenu, subProductInMenu);

      if (currentStock.promo?.dateSettings?.happyHours?.isHappyHour) {
        happyHourProducts++;
      }

      const updatedShoppingCart = updateProductInShoppingCart(
        currentStock,
        productInShoppingCart.quantity,
        shoppingCartToSave,
        productInShoppingCart,
        productInMenu
      );

      shoppingCartToSave = { ...updatedShoppingCart };
    });

    happyHourProducts > 0 ? setSomeHappyHour(true) : setSomeHappyHour(false);
    setShoppingCart(shoppingCartToSave);
  };

  // Evaluate menu each minute it there is a happyHour
  let evaluatePromo;
  React.useEffect(() => {
    if (someHappyHour && !evaluatePromo) {
      console.log("there's a happyHour, evaluating promo, is active");
      evaluatePromo = setInterval(() => {
        console.log("evaluating promo, step: ");
        evaluateShoppingCart(menu);
      }, 60000);
    } else if (!someHappyHour) {
      clearInterval(evaluatePromo);
    }

    return () => {
      clearInterval(evaluatePromo);
    };
  }, [someHappyHour]);

  // Evaluate menu each 10 minutes
  React.useEffect(() => {
    // Menu
    axios
      .get(`${process.env.REACT_APP_MENU_DATA_BASE_URL}/business/${id}/menu`)
      .then((res) => {
        // Evaluate Shopping Cart
        evaluateShoppingCart(res.data);

        if (res.data?.[0]?.products && res.data[0].category.name) {
          // State to validate shopping cart (shopping cart view)
          setMenu(res.data);
        }
      })
      .catch((error) => console.log(error));

    // Set first category by default in the menu
    setCategorySelected({
      ...categorySelected,
      anySelected: false,
    });

    if (configPayU) {
      getDataWebCheckoutPayu();
    }

    let evaluateMenuUpdate;
    if (!evaluateMenuUpdate) {
      console.log("Evaluating menu interval is active");
      evaluateMenuUpdate = setInterval(() => {
        console.log("Evaluating menu");
        // Menu
        axios
          .get(
            `${process.env.REACT_APP_MENU_DATA_BASE_URL}/business/${id}/menu`
          )
          .then((res) => {
            // Evaluate Shopping Cart
            evaluateShoppingCart(res.data);

            if (res.data?.[0]?.products && res.data[0].category.name) {
              // State to validate shopping cart (shopping cart view)
              setMenu(res.data);
            }
          })
          .catch((error) => console.log(error));
      }, 600000);
    }

    return () => {
      clearInterval(evaluateMenuUpdate);
    };
  }, []);

  /* Show modal thanks */
  const [isOpenThanks, setIsOpenThanks] = React.useState(false);

  /* ---------- Show modal start/register ---------- */
  const [isOpenRegister, setIsOpenRegister] = React.useState(false); // Login, access to shopping cart

  const toggleModalRegister = () => {
    setIsOpenRegister(!isOpenRegister);
  };

  /* ---------- Redirect to menu ---------- */
  const [redirectMenu, setRedirectMenu] = React.useState(false);
  const redirectToMenu = () => {
    setRedirectMenu(true);
  };

  if (
    redirectMenu ||
    (isOpenThanks === false && DATA_PRODUCTS.length === 0) ||
    !session
  ) {
    return <Redirect to={`/menu/${id}${urlExtension}`} />;
  }

  return (
    <ContainerGeneral
      backgroundColor={
        businessData.config?.style?.digitalMenu?.backgroundColor ?? ""
      }
    >
      <div className="container">
        <Header
          business={id}
          session={session}
          urlExtension={urlExtension}
          menuInactive={true}
          btnColor={
            businessData.config?.style?.digitalMenu?.btnPrimaryColor ?? ""
          }
          logOut={logOut}
        />
        <Head
          quantity={quantity}
          lblTextColor={
            businessData.config?.style?.digitalMenu?.lblTextColor ?? ""
          }
          backgroundColor={
            businessData.config?.style?.digitalMenu?.backgroundColor ?? ""
          }
          logOut={logOut}
        />
        <SubContainer>
          <ContainerProducts>
            <div className="message">
              <Btn
                onClick={redirectToMenu}
                btnColor={
                  businessData.config?.style?.digitalMenu?.btnPrimaryColor ?? ""
                }
              >
                Ir al menú
              </Btn>
            </div>
            <>
              {DATA_PRODUCTS.map((product, index) => {
                return (
                  <ProductCar
                    key={index}
                    // inside={urlQueries["qrCode"]}
                    product={product}
                    changeAmount={changeAmount}
                    setChangeAmount={setChangeAmount}
                  />
                );
              })}
            </>
            <ContainerTotal
              lblTextColor={
                businessData.config?.style?.digitalMenu?.lblTextColor ?? ""
              }
            >
              {!urlQueries["type"] && deliveryFormState === "delivery" && (
                <>
                  <div className="subtotal">
                    <p>Subtotal</p>
                    <p>
                      {subTotal.toLocaleString(configCurrency.bcp47, {
                        style: "currency",
                        currency: configCurrency.iso_4217,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </div>
                  <div className="address">
                    <p>Domicilio</p>
                    <p>
                      {urlQueries["type"] === "INSIDE_RESTAURANT"
                        ? noDelivery.toLocaleString(configCurrency.bcp47, {
                            style: "currency",
                            currency: configCurrency.iso_4217,
                          })
                        : configDeliveryCost.toLocaleString(
                            configCurrency.bcp47,
                            {
                              style: "currency",
                              currency: configCurrency.iso_4217,
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }
                          )}
                    </p>
                  </div>
                </>
              )}
              <div className="total">
                <p>Total</p>
                <p>
                  {total.toLocaleString(configCurrency.bcp47, {
                    style: "currency",
                    currency: configCurrency.iso_4217,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
            </ContainerTotal>
          </ContainerProducts>
          <ContainerForm>
            <Form
              business={id}
              DATA_PRODUCTS={DATA_PRODUCTS}
              // inside={urlQueries["qrCode"]}
              inside={urlQueries["type"]}
              deliveryFormState={deliveryFormState}
              setRedirectMenu={setRedirectMenu}
              isOpenThanks={isOpenThanks}
              setIsOpenThanks={setIsOpenThanks}
              phoneNumber={number}
              // total={total}
              payu={payu}
              changeAmount={changeAmount}
              getDataWebCheckoutPayu={getDataWebCheckoutPayu}
              setDeliveryForm={setDeliveryForm}
              toggleModalRegister={toggleModalRegister}
            />
          </ContainerForm>
        </SubContainer>
        <Footer shoppingCart={true} />
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalRegister
        isOpen={isOpenRegister}
        closeModal={toggleModalRegister}
        additionalMessage="Su sesión ha expirado, ingrese nuevamente"
      />
    </ContainerGeneral>
  );
}

const ContainerGeneral = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-White)"};
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  @media (max-width: 1023px) {
    margin-top: 80px;
  }
  @media (max-width: 1023px) and (orientation: landscape) {
    margin-top: 0;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const ContainerProducts = styled.div`
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    width: 100%;
    padding: 0 20px;
  }
  .message {
    width: 100%;
    max-width: 500px;
    height: 60px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    @media (max-width: 991px) {
      max-width: none;
    }
  }
`;

const ContainerTotal = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: none;
  }
  .subtotal,
  .address,
  .total {
    display: flex;
    justify-content: space-between;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    text-transform: uppercase;
  }
  .subtotal p,
  .address p {
    font-size: 0.8rem;
  }
  .total p {
    font-weight: 700;
  }
`;

const ContainerForm = styled.div`
  width: 50%;
  height: fit-content;
  border-radius: 50px;
  top: 84px;
  background-color: var(--color-Back-Yelow);
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    border-radius: 25px;
  }
`;

const Btn = styled.div`
  padding: 1rem;
  width: 100%;
  height: 40px;
  background-color: ${(props) =>
    props.btnColor ? props.btnColor : "var(--color-Green)"};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-White);
  @media (max-width: 767px) {
    font-size: 0.7rem;
  }
  &:hover {
    cursor: pointer;
  }
`;
