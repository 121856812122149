import React from "react";
import styled from "styled-components";

// import IconLogOut from "../../assets/images/icons/log-out.svg";

export default function Head({
  quantity = 0,
  lblTextColor,
  backgroundColor,
  // logOut,
}) {
  return (
    <ContainerGeneral
      lblTextColor={lblTextColor}
      backgroundColor={backgroundColor}
    >
      <div>
        <h1>Carrito de compras</h1>
        <p>
          Tienes{" "}
          {quantity === 1 ? `${quantity} producto` : `${quantity} productos`} en
          tu carrito
        </p>
      </div>
      {/* <img src={IconLogOut} alt="Log out" onClick={() => logOut()} /> */}
    </ContainerGeneral>
  );
}

const ContainerGeneral = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 99;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-White)"};
  > div h1 {
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
  }
  > div p {
    font-size: 0.8rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
  }
  > img:hover {
    cursor: pointer;
  }
  @media (max-width: 1023px) {
    top: 80px;
    div > h1 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 1023px) and (orientation: landscape) {
    top: 0;
  }
`;
