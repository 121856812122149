import React from "react";

function NotMenu({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        fill={color ? color : "#828282"}
        fillRule="evenodd"
        d="M100 1.96L98.04 0 78.627 19.41h-14.95c-5.523 0-10 4.478-10 10v7.73c0 2.051.617 3.958 1.676 5.545L43.106 54.933a9.954 9.954 0 00-4.974-1.322H11.916c-5.523 0-10 4.477-10 10v7.73c0 5.522 4.477 10 10 10h4.783L0 98.038 1.96 100 100 1.96zM46.886 58.773L24.318 81.34h13.814c5.523 0 10-4.477 10-10v-7.73a9.955 9.955 0 00-1.246-4.838zm39.361-39.361L59.452 46.206c1.284.6 2.716.934 4.226.934h26.216c5.523 0 10-4.477 10-10v-7.73c0-5.522-4.477-10-10-10h-3.647zm-74.331 0c-5.523 0-10 4.477-10 10v7.73c0 5.522 4.477 10 10 10h26.216c5.523 0 10-4.478 10-10v-7.73c0-5.523-4.477-10-10-10H11.916zm51.762 34.2c-5.523 0-10 4.477-10 10v7.73c0 5.522 4.477 10 10 10h26.216c5.523 0 10-4.478 10-10v-7.73c0-5.523-4.477-10-10-10H63.678z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default NotMenu;