import React from "react";
import styled from "styled-components";

import { MENU_SHOPPINGCART_CONTEXT } from "../../../context/MenuShoppingCartProvider";

import { AdditionalProductCounter } from "../../shared/Counter";

export default function Additional({
  currentAdditional,
  additionals,
  setAdditionals,
  subProducts,
  setTotalOfTheAdditionals,
  totalOfTheMainProduct,
  setTotalOfTheProduct,
}) {
  const { configCurrency, businessData } = React.useContext(
    MENU_SHOPPINGCART_CONTEXT
  );

  const { lblTextColor = "" } = businessData.config?.style?.digitalMenu ?? {};

  const checkOrUncheckAdditional = (e) => {
    if (e.target.checked) {
      const initialPrice =
        subProducts.length > 0 &&
        currentAdditional.product.locationsStock?.[0]?.price
          ? currentAdditional.product.locationsStock?.[0]?.price
          : currentAdditional.product.price || 0;

      const additionalsUpdated = {
        ...additionals,
        [e.target.name]: {
          externalId: currentAdditional.product._id,
          name: currentAdditional.product.name,
          qty: 1,
          price: initialPrice,
          total: initialPrice,
        },
      };

      setAdditionals(additionalsUpdated);
      calculateTotal(additionalsUpdated);
    } else {
      delete additionals[e.target.name];
      setAdditionals({ ...additionals });
      calculateTotal({ ...additionals });
    }
  };

  const changeAdditionalQty = (name, qty) => {
    const additionalsUpdated = {
      ...additionals,
      [name]: {
        ...additionals[name],
        qty: qty,
        total: qty * additionals[name]["price"],
      },
    };

    setAdditionals(additionalsUpdated);
    calculateTotal(additionalsUpdated);
  };

  const calculateTotal = (additionalsUpdated) => {
    let totalOfTheAdditionals = 0;
    for (let additional in additionalsUpdated) {
      totalOfTheAdditionals += additionalsUpdated[additional].total;
    }

    setTotalOfTheAdditionals(totalOfTheAdditionals);
    setTotalOfTheProduct(totalOfTheAdditionals + totalOfTheMainProduct);
  };

  return (
    <React.Fragment key={currentAdditional._id}>
      <Label lblTextColor={lblTextColor}>
        {currentAdditional.product?.name}
        {" - "}
        {!additionals[currentAdditional.product?.name] &&
          currentAdditional.product?.locationsStock?.[0]?.price?.toLocaleString(
            configCurrency.bcp47,
            {
              style: "currency",
              currency: configCurrency.iso_4217,
            }
          )}
        <Input
          type="checkbox"
          name={currentAdditional.product?.name}
          onChange={checkOrUncheckAdditional}
          checked={additionals[currentAdditional.product?.name] ? true : false}
        />
        <Indicator />
      </Label>
      {currentAdditional.product &&
        currentAdditional.product.name &&
        additionals[currentAdditional.product.name] && (
          <CountOptions lblTextColor={lblTextColor}>
            <AdditionalProductCounter
              initialValue={additionals[currentAdditional.product.name]["qty"]}
              updateValue={(qty) =>
                changeAdditionalQty(currentAdditional.product.name, qty)
              }
            />
            <p>
              {additionals[currentAdditional.product.name][
                "total"
              ].toLocaleString(configCurrency.bcp47, {
                style: "currency",
                currency: configCurrency.iso_4217,
              })}
            </p>
          </CountOptions>
        )}
    </React.Fragment>
  );
}

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 4px 20px;
  padding-left: 5px;
  font-size: 0.8rem;
  color: ${(props) =>
    props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
`;

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1px;
  left: -1rem;
  border: 1px solid var(--color-Gray);
  background-color: var(--color-White);
  border-radius: 0.2em;

  ${Input}:not(:disabled):checked & {
    background: #e7e7e7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + & {
    background-color: var(--color-Gray);
    border-color: var(--color-Gray);
  }

  ${Input}:checked + &::after {
    display: block;
    top: 1px;
    left: 5px;
    width: 25%;
    height: 50%;
    border: solid var(--color-White);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const CountOptions = styled.div`
  min-width: 100px;
  height: 40px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    > svg:hover {
      cursor: pointer;
    }
  }
  > p {
    text-align: center;
    font-size: 0.9rem;
    padding: 0 8px 0 8px;
  }
  .hidden {
    opacity: 0;
    & svg:hover {
      cursor: auto;
    }
  }
`;
