import React from "react";
import styled from "styled-components";

import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";

import Additional from "./product-components/Additional";
import ComboSelectionOne from "./product-components/ComboSelectionOne";
import { MainProductCounter } from "../shared/Counter";

/* Icons */
import iconoClose from "../../assets/images/icons/close.svg";
import IconoLess from "../../assets/images/icons/Less";
import IconoMore from "../../assets/images/icons/More";
import IconSales from "../../assets/images/icons/Sales";
import IconSales2x1 from "../../assets/images/icons/Sales2x1";
import restobarIcon from "../../assets/images/default-product-image.svg";

import {
  estimateAvailabilityOfThePromotion,
  calculateQuantityToPay,
  calculatePriceToPay,
} from "../shared/evaluatePromotion";

export default function DetailProduct({
  product,
  subProducts,
  isOpen,
  closeModal,
  totalOfTheProduct,
  setTotalOfTheProduct,
}) {
  const {
    _id: externalId,
    name,
    urlImage: image,
    description: productDesc = "",
    notes,
    extra: additionalsOfTheMainProduct,
    configCombo,
    type,
    waiterOrderArea,
    variablePrice = {}, //? variablePrice field in subproducts ?
    locationsStock = [],
    category,
  } = product.internalId ?? {};

  const variablePriceCode = {
    MayorValor: "mayor valor",
    MenorValor: "menor valor,",
  };

  const {
    functionality,
    shoppingCart,
    setShoppingCart,
    configCurrency,
    businessData,
  } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  const {
    backgroundColor = "",
    btnPrimaryColor: btnColor = "",
    lblTextColor = "",
  } = businessData.config?.style?.digitalMenu ?? {};

  /* ------------------ Logic of variant products (products with subproducts) -------------------- */
  // Subproduct selected
  const [variantProduct, setVariantProduct] = React.useState(() => {
    let initialVariant = {};

    if (subProducts?.length > 0) {
      initialVariant = subProducts[0];
    }

    return initialVariant;
  });

  const additionalsAvailable =
    variantProduct.extra || additionalsOfTheMainProduct;

  const handlerVariantProduct = (e) => {
    const subProduct = subProducts[e.target.value];
    setVariantProduct(subProduct);

    // Promo
    const [stock] = subProduct.locationsStock ?? [];
    const availablePromo = estimateAvailabilityOfThePromotion(stock?.promo);
    if (availablePromo) {
      // Promo Porcentaje --------
      const { value: promoPercentage = 0 } =
        stock?.promo?.data?.typePorcentaje ?? {};

      // Promo PorcentajeCantidad -----------
      const { values: cantidadVsPercentages = [] } =
        stock?.promo?.data?.typePorcentajeCantidad ?? {};

      // Apply the first matching element
      const [promoApplied] = cantidadVsPercentages.filter(
        (e) => e.qtyMin <= 1 && 1 <= e.qtyMax
      );

      const promoQtyPercentage = promoApplied?.value ?? 0;

      const promoValue = promoPercentage + promoQtyPercentage;
      const startingPrice = (subProduct.price * (100 - promoValue)) / 100;
      setTotalOfTheMainProduct(startingPrice);
      setTotalOfTheProduct(startingPrice);
    } else {
      setTotalOfTheMainProduct(subProduct.price);
      setTotalOfTheProduct(subProduct.price);
    }

    setAvailablePromotion(availablePromo);
    setCount(1);
    setAdditionals({});
  };
  // -------------------------

  /* ----------- Logic of description ------------- */
  const [description, setDescription] = React.useState("");
  const [custom, setCustom] = React.useState({});

  // Notes
  const checkboxCustomChange = (e) => {
    setCustom({ ...custom, [e.target.name]: e.target.checked });
  };

  const handlerTextArea = (e) => {
    setDescription(e.target.value);
  };
  // ---------------------

  /* ------------------ Logic of combos -------------------- */
  // selectionTypeOneChange
  const [comboSelectionOne, setComboSelectionOne] = React.useState({});

  React.useEffect(() => {
    // Subproduct combo
    setComboSelectionOne({});
  }, [variantProduct]);

  // selectionTypeSeveralChange
  const [countOption, setCountOption] = React.useState(() => {
    const options = {};

    if (type === "Combo" && configCombo && configCombo.options) {
      configCombo.options.forEach((option) => {
        if (option.selectionType === "Several") {
          option.products.forEach((item) => {
            options[item.product.name] = {
              externalId: item.product._id,
              name: item.product.name,
              qty: item.quantity || 0,
              optionId: option._id,
              optionName: option.name,
              severalOption: option.severalOption,
            };
          });
        }
      });
    }

    return options;
  });

  // State for verification of approved and qty option attribute
  const [severalOption, setSeveralOption] = React.useState(() => {
    const options = {};

    if (type === "Combo" && configCombo?.options) {
      configCombo.options.forEach((option) => {
        if (option.selectionType === "Several") {
          const interval = option.severalOption ?? { min: 0 };
          const defaultQty = option.products.reduce(
            (counter, product) => counter + product.quantity,
            0
          );

          options[option._id] = {
            optionId: option._id,
            optionName: option.name,
            qty: defaultQty,
            severalOption: option.severalOption,
            approved: !(
              defaultQty < interval.min ||
              (interval.max && defaultQty > interval.max) ||
              defaultQty === 0
            ),
          };
        }
      });
    }

    return options;
  });

  React.useEffect(() => {
    const options = {};
    const severalOptions = {};

    // Subproduct combo
    if (variantProduct.type === "Combo") {
      variantProduct.configCombo?.options?.forEach((option) => {
        if (option.selectionType === "Several") {
          option.products.forEach((item) => {
            options[item.product.name] = {
              externalId: item.product._id,
              name: item.product.name,
              qty: item.quantity || 0,
              optionId: option._id,
              optionName: option.name,
              severalOption: option.severalOption,
            };
          });
        }
      });
    }

    if (variantProduct.type === "Combo") {
      variantProduct.configCombo?.options?.forEach((option) => {
        if (option.selectionType === "Several") {
          const interval = option.severalOption ?? { min: 0 };
          const defaultQty = option.products.reduce(
            (counter, product) => counter + product.quantity,
            0
          );

          severalOptions[option._id] = {
            optionId: option._id,
            optionName: option.name,
            qty: defaultQty,
            severalOption: option.severalOption,
            approved: !(
              defaultQty < interval.min ||
              (interval.max && defaultQty > interval.max) ||
              defaultQty === 0
            ),
          };
        }
      });
    }

    if (variantProduct.configCombo?.options?.length > 0) {
      setCountOption(options);
      setSeveralOption(severalOptions);
    }
  }, [variantProduct]);

  const handlerCountOptionMore = (name, optionId) => {
    setCountOption({
      ...countOption,
      [name]: {
        ...countOption[name],
        qty: countOption[name]["qty"] + 1,
      },
    });

    // Enable aggregate button
    const severalOptions = { ...severalOption };
    const qty = severalOptions[optionId].qty + 1;
    severalOptions[optionId].qty = qty;

    const interval = severalOptions[optionId].severalOption ?? { min: 0 };

    if (
      !(qty < interval.min || (interval.max && qty > interval.max) || qty === 0)
    ) {
      severalOptions[optionId].approved = true;
    } else {
      severalOptions[optionId].approved = false;
    }

    setSeveralOption(severalOptions);

    if (
      Object.values(severalOptions).every((option) => option.approved === true)
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handlerCountOptionLess = (name, optionId) => {
    setCountOption({
      ...countOption,
      [name]: {
        ...countOption[name],
        qty: countOption[name]["qty"] - 1,
      },
    });

    // Enable aggregate button
    const severalOptions = { ...severalOption };
    const qty = severalOptions[optionId].qty - 1;
    severalOptions[optionId].qty = qty;

    const interval = severalOptions[optionId].severalOption ?? { min: 0 };

    if (
      !(qty < interval.min || (interval.max && qty > interval.max) || qty === 0)
    ) {
      severalOptions[optionId].approved = true;
    } else {
      severalOptions[optionId].approved = false;
    }

    setSeveralOption(severalOptions);

    if (
      Object.values(severalOptions).every((option) => option.approved === true)
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  // Enable aggregate button
  const [disabledButton, setDisabledButton] = React.useState(false);
  const [combosUniqueOptions, setCombosUniqueOptions] = React.useState();

  React.useEffect(() => {
    if (type === "Combo") {
      let uniqueOptions = 0;

      configCombo?.options?.forEach((option) => {
        option.selectionType === "One" && uniqueOptions++;

        if (option.selectionType === "Several") {
          const interval = option.severalOption ?? { min: 0 };
          const defaultQty = option.products.reduce(
            (counter, product) => counter + product.quantity,
            0
          );

          if (
            defaultQty < interval.min ||
            (interval.max && defaultQty > interval.max) ||
            defaultQty === 0
          ) {
            setDisabledButton(true);
          }
        }
      });

      if (uniqueOptions > 0) {
        setCombosUniqueOptions(uniqueOptions);
        setDisabledButton(true);
      }
    }

    // Subproduct combo
    if (variantProduct.type === "Combo") {
      let uniqueOptions = 0;

      variantProduct?.configCombo?.options?.forEach((option) => {
        option.selectionType === "One" && uniqueOptions++;

        if (option.selectionType === "Several") {
          const interval = option.severalOption ?? { min: 0 };
          const defaultQty = option.products.reduce(
            (counter, product) => counter + product.quantity,
            0
          );

          if (
            defaultQty < interval.min ||
            (interval.max && defaultQty > interval.max) ||
            defaultQty === 0
          ) {
            setDisabledButton(true);
          }
        }
      });

      if (uniqueOptions > 0) {
        setCombosUniqueOptions(uniqueOptions);
        setDisabledButton(true);
      }
    }
  }, [configCombo, type, variantProduct]);

  // selectionTypeAllChange
  const [allOptions, setAllOptions] = React.useState(() => {
    const options = {};

    if (type === "Combo" && configCombo && configCombo.options) {
      configCombo.options.forEach((option) => {
        if (option.selectionType === "All") {
          option.products.forEach((item) => {
            options[item.product.name] = {
              externalId: item.product._id,
              name: item.product.name,
              qty: item.quantity,
            };
          });
        }
      });
    }

    return options;
  });

  React.useEffect(() => {
    const options = {};

    // Subproduct combo
    if (variantProduct.type === "Combo") {
      variantProduct?.configCombo?.options?.forEach((option) => {
        if (option.selectionType === "All") {
          option.products.forEach((item) => {
            options[item.product.name] = {
              externalId: item.product._id,
              name: item.product.name,
              qty: item.quantity,
            };
          });
        }
      });
    }

    variantProduct?.configCombo?.options?.length > 0 && setAllOptions(options);
  }, [variantProduct]);
  // -------------------------

  /* ----------------- Management of promos ---------------- */
  const [mainStock = {}] = locationsStock;

  const promoData = {
    Cantidad: {
      type: "typeCantidad",
      icon: <IconSales2x1 color={btnColor} />,
    },
    Porcentaje: {
      type: "typePorcentaje",
      icon: <IconSales color={btnColor} />,
    },
    ProcentajeCantidad: {
      type: "typePorcentajeCantidad",
      icon: <IconSales color={btnColor} />,
    },
  };
  // -------------------

  /* --------------- Logic of extras -------------- */
  const [additionals, setAdditionals] = React.useState({});
  // -------------------

  /* ----------------- Logic, aggregate to shopping cart ---------------- */
  const calculateStartingPrice = () => {
    if (subProducts.length > 0) {
      // Subproduct
      // Initial price for the first subProduct by default
      const [subProduct] = subProducts;
      const [stock] = subProduct.locationsStock ?? [];
      const availablePromo = estimateAvailabilityOfThePromotion(stock?.promo);

      const priceToPay = calculatePriceToPay(
        1,
        stock.promo,
        stock.price || subProduct.price || 0,
        availablePromo
      );

      return priceToPay;
    } else {
      // Main product
      // Initial price of the main product
      const availablePromo = estimateAvailabilityOfThePromotion(
        mainStock?.promo
      );

      const priceToPay = calculatePriceToPay(
        1,
        mainStock.promo,
        mainStock.price || 0,
        availablePromo
      );

      return priceToPay;
    }
  };

  const [totalOfTheAdditionals, setTotalOfTheAdditionals] = React.useState(0);
  const [totalOfTheMainProduct, setTotalOfTheMainProduct] = React.useState(
    calculateStartingPrice
  );
  const [availablePromotion, setAvailablePromotion] = React.useState(() => {
    if (subProducts?.length > 0) {
      const [subProduct] = subProducts;
      const [stock] = subProduct.locationsStock ?? [];
      return estimateAvailabilityOfThePromotion(stock?.promo);
    } else {
      return estimateAvailabilityOfThePromotion(mainStock?.promo);
    }
  });

  // Counter of the product
  const [count, setCount] = React.useState(1);

  React.useEffect(() => {
    setTotalOfTheProduct(calculateStartingPrice());
  }, []);

  const aggregationController = () => {
    let checkNotes = "";
    for (let item in custom) {
      if (custom[item]) {
        checkNotes += item + " ";
      }
    }

    const newItem = `${name}_${Object.keys(shoppingCart).length}`;

    const shoppingCartUpdated = {
      ...shoppingCart,
      [newItem]: {
        externalId: variantProduct._id || externalId,
        id_shoppingCart: newItem,
        categoryId: category._id,
        productId: externalId,
        subProductId: variantProduct._id,
        name: variantProduct.name || name,
        quantity: count,
        price: totalOfTheMainProduct / count,
        variablePrice: variablePrice.isVariablePrice,
        subTotal: totalOfTheProduct, // Includes additionals
        extras: Object.values(additionals),
        notes: checkNotes + description,
        image: variantProduct.urlImage || image,
        waiterOrderArea: waiterOrderArea ?? "",
        combo: {
          ...comboSelectionOne,
          ...countOption,
          ...allOptions,
        },
      },
    };

    if (availablePromotion) {
      const normalMainPrice = calculateStartingPrice();
      const normalTotalPrice = normalMainPrice * count + totalOfTheAdditionals;

      shoppingCartUpdated[newItem].promo = {
        priceFull: normalTotalPrice,
        discount: normalTotalPrice - totalOfTheProduct,
      };
    }

    setShoppingCart(shoppingCartUpdated);
    handleClose();
  };

  const updateProduct = (stock, qty, totalOfTheAdditionals) => {
    const availablePromo = estimateAvailabilityOfThePromotion(stock?.promo);

    const quantityToPay = calculateQuantityToPay(
      qty,
      stock.promo,
      availablePromo
    );

    const priceToPay = calculatePriceToPay(
      qty,
      stock.promo,
      stock.price,
      availablePromo
    );

    setAvailablePromotion(availablePromo);
    setTotalOfTheMainProduct(priceToPay * quantityToPay);
    setTotalOfTheProduct(priceToPay * quantityToPay + totalOfTheAdditionals);
  };

  const changeQuantityOfTheMainProduct = (quantity) => {
    if (subProducts.length > 0) {
      // Subproduct
      const [stock] = variantProduct.locationsStock;

      if (!stock.price) {
        const modifiedStock = { ...stock };
        modifiedStock.price = subProducts?.[0].price || 0;
        updateProduct(modifiedStock, quantity, totalOfTheAdditionals);
      } else {
        updateProduct(stock, quantity, totalOfTheAdditionals);
      }
    } else {
      // Main product
      updateProduct(mainStock, quantity, totalOfTheAdditionals);
    }

    setCount(quantity);
  };
  // -------------------

  /* ----------------- handler Scroll detail product ---------------- */
  const [scroll, setScroll] = React.useState(1);
  const [scroll2, setScroll2] = React.useState(1);

  const handleScroll = (e) => {
    if ((e.target.offsetHeight * 100) / e.target.scrollHeight < 70) {
      if (e.target.scrollTop > 40) {
        setScroll(0.35);
      }
    }
    if (e.target.scrollTop < 5) {
      setScroll(1);
    }
    if ((e.target.offsetHeight * 100) / e.target.scrollHeight > 70) {
      setScroll2(e.target.scrollTop);
    }
  };
  // -------------------

  // Temporal logic function, when the modal is closed or a new product is aggregated
  const handleClose = () => {
    setTotalOfTheMainProduct(calculateStartingPrice());
    setTotalOfTheAdditionals(0);
    setAdditionals({});
    setTotalOfTheProduct(calculateStartingPrice());
    setCount(1); // ? reset in counter component
    // ? reset in type one selection
    closeModal();
  };

  return (
    <Container>
      <div className={`modal ${isOpen ? "modal-open" : null}`}>
        <ContainerModal backgroundColor={backgroundColor}>
          <Image
            image={
              subProducts.length > 0 && variantProduct.urlImage
                ? variantProduct.urlImage
                : image
            }
            scroll={scroll}
            scroll2={scroll2}
          >
            <img
              className="close"
              src={iconoClose}
              alt="cerrar"
              onClick={() => handleClose()}
            />
            <h4 className="nameProduct">{name}</h4>
            <div className="image"></div>
          </Image>
          <Customization
            lblTextColor={lblTextColor}
            btnColor={btnColor}
            onScroll={handleScroll}
          >
            <div className="title">
              <h4>
                {name}
                {mainStock?.promo?.isActive && availablePromotion && (
                  <span className="sale">
                    {promoData[mainStock?.promo?.type]?.icon}
                    <p>{mainStock?.promo?.name}</p>
                  </span>
                )}
              </h4>
              <img
                className="close"
                src={iconoClose}
                alt="cerrar"
                onClick={() => closeModal()}
              />
            </div>
            <div className="containerChecks">
              <div className="description">{productDesc}</div>
              {variablePrice.isVariablePrice && (
                <p className="description_variable_price">
                  Se cobrará segun el precio del producto de{" "}
                  {variablePriceCode[variablePrice.type]}
                </p>
              )}
              {/* ----------------- VARIANT PRODUCTS ----------------- */}
              {subProducts.length > 0 && (
                <>
                  <h4>Opción</h4>
                  <Select>
                    <select onChange={handlerVariantProduct}>
                      {subProducts.map((product, idx) => (
                        <option value={idx} key={idx}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </Select>
                  <div className="description">
                    {variantProduct.description}
                  </div>
                  {variantProduct.locationsStock?.[0]?.promo?.isActive &&
                    availablePromotion && (
                      <Sales btnColor={btnColor}>
                        {
                          promoData[
                            variantProduct.locationsStock?.[0]?.promo?.type
                          ]?.icon
                        }
                        <h5>
                          {variantProduct.locationsStock?.[0]?.promo?.name}
                        </h5>
                      </Sales>
                    )}
                  {variantProduct?.type === "Combo" &&
                    variantProduct?.configCombo?.options?.map((option, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          {option.selectionType === "One" && (
                            <h4>{option.name}</h4>
                          )}
                          {option.selectionType === "One"
                            ? option.products.map((item, idx) => {
                                return (
                                  <ComboSelectionOne
                                    key={idx}
                                    item={item}
                                    option={option}
                                    comboSelectionOne={comboSelectionOne}
                                    setComboSelectionOne={setComboSelectionOne}
                                    variablePrice={variablePrice}
                                    setTotalOfTheProduct={setTotalOfTheProduct}
                                    combosUniqueOptions={combosUniqueOptions}
                                    setDisabledButton={setDisabledButton}
                                    totalOfTheAdditionals={
                                      totalOfTheAdditionals
                                    }
                                    setTotalOfTheMainProduct={
                                      setTotalOfTheMainProduct
                                    }
                                  />
                                );
                              })
                            : option.selectionType === "Several"
                            ? option.products.map((item, idx) => {
                                return (
                                  <React.Fragment key={idx}>
                                    {idx === 0 && (
                                      <h4>
                                        {option.name}
                                        <span>
                                          {option.severalOption &&
                                            option.severalOption["max"] > 0 &&
                                            `  (min. ${option.severalOption["min"]} - máx. ${option.severalOption["max"]})`}
                                        </span>
                                      </h4>
                                    )}
                                    <ContainerOptionCounter>
                                      <CountOptions lblTextColor={lblTextColor}>
                                        <div
                                          className={
                                            (!countOption[item.product.name] &&
                                              item.quantity) ||
                                            (countOption[item.product.name] &&
                                              countOption[item.product.name][
                                                "qty"
                                              ] > 0)
                                              ? null
                                              : "hidden"
                                          }
                                          onClick={
                                            (!countOption[item.product.name] &&
                                              item.quantity) ||
                                            (countOption[item.product.name] &&
                                              countOption[item.product.name][
                                                "qty"
                                              ] > 0)
                                              ? () =>
                                                  handlerCountOptionLess(
                                                    item.product.name,
                                                    option._id
                                                  )
                                              : null
                                          }
                                        >
                                          <IconoLess color={lblTextColor} />
                                        </div>
                                        <p>
                                          {countOption[item.product.name]
                                            ? countOption[item.product.name][
                                                "qty"
                                              ]
                                            : item.quantity
                                            ? item.quantity
                                            : 0}
                                        </p>
                                        <div
                                          onClick={() =>
                                            handlerCountOptionMore(
                                              item.product.name,
                                              option._id
                                            )
                                          }
                                        >
                                          <IconoMore color={lblTextColor} />
                                        </div>
                                      </CountOptions>
                                      <p>{item.product.name}</p>
                                    </ContainerOptionCounter>
                                  </React.Fragment>
                                );
                              })
                            : // option.selectionType === 'All'
                              option.products.map((item, idx) => {
                                return (
                                  <React.Fragment key={idx}>
                                    <h4>{option.name}</h4>
                                    <ContainerOptionCounter>
                                      <p>
                                        {item.product.name}
                                        {item.quantity && ` X ${item.quantity}`}
                                      </p>
                                    </ContainerOptionCounter>
                                  </React.Fragment>
                                );
                              })}
                        </React.Fragment>
                      );
                    })}
                </>
              )}

              {/* ----------------- NOTES OBS ----------------- */}
              {notes.length > 0 && (
                <>
                  <h4>Observaciones</h4>
                  {notes.map((note, idx) => {
                    return (
                      <Label key={idx} lblTextColor={lblTextColor}>
                        {note}
                        <Input
                          type="checkbox"
                          name={note}
                          onChange={checkboxCustomChange}
                        />
                        <Indicator />
                      </Label>
                    );
                  })}
                </>
              )}

              {/* ------------------- COMBOS ------------------- */}
              {type === "Combo" &&
                configCombo &&
                configCombo.options &&
                configCombo.options.map((option, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {option.selectionType === "One" && <h4>{option.name}</h4>}
                      {option.selectionType === "One"
                        ? option.products.map((item, idx) => {
                            return (
                              <ComboSelectionOne
                                key={idx}
                                item={item}
                                option={option}
                                comboSelectionOne={comboSelectionOne}
                                setComboSelectionOne={setComboSelectionOne}
                                variablePrice={variablePrice}
                                setTotalOfTheProduct={setTotalOfTheProduct}
                                combosUniqueOptions={combosUniqueOptions}
                                setDisabledButton={setDisabledButton}
                                totalOfTheAdditionals={totalOfTheAdditionals}
                                setTotalOfTheMainProduct={
                                  setTotalOfTheMainProduct
                                }
                              />
                            );
                          })
                        : option.selectionType === "Several"
                        ? option.products.map((item, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                {idx === 0 && (
                                  <h4>
                                    {option.name}
                                    <span>
                                      {option.severalOption &&
                                        option.severalOption["max"] > 0 &&
                                        `  (min. ${option.severalOption["min"]} - máx. ${option.severalOption["max"]})`}
                                    </span>
                                  </h4>
                                )}
                                <ContainerOptionCounter>
                                  <CountOptions lblTextColor={lblTextColor}>
                                    <div
                                      className={
                                        (!countOption[item.product.name] &&
                                          item.quantity) ||
                                        (countOption[item.product.name] &&
                                          countOption[item.product.name][
                                            "qty"
                                          ] > 0)
                                          ? null
                                          : "hidden"
                                      }
                                      onClick={
                                        (!countOption[item.product.name] &&
                                          item.quantity) ||
                                        (countOption[item.product.name] &&
                                          countOption[item.product.name][
                                            "qty"
                                          ] > 0)
                                          ? () =>
                                              handlerCountOptionLess(
                                                item.product.name,
                                                option._id
                                              )
                                          : null
                                      }
                                    >
                                      <IconoLess color={lblTextColor} />
                                    </div>
                                    <p>
                                      {countOption[item.product.name]
                                        ? countOption[item.product.name]["qty"]
                                        : item.quantity
                                        ? item.quantity
                                        : 0}
                                    </p>
                                    <div
                                      onClick={() =>
                                        handlerCountOptionMore(
                                          item.product.name,
                                          option._id
                                        )
                                      }
                                    >
                                      <IconoMore color={lblTextColor} />
                                    </div>
                                  </CountOptions>
                                  <p>{item.product.name}</p>
                                </ContainerOptionCounter>
                              </React.Fragment>
                            );
                          })
                        : // option.selectionType === 'All'
                          option.products.map((item, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                <h4>{option.name}</h4>
                                <ContainerOptionCounter>
                                  <p>
                                    {item.product.name}
                                    {item.quantity && ` X ${item.quantity}`}
                                  </p>
                                </ContainerOptionCounter>
                              </React.Fragment>
                            );
                          })}
                    </React.Fragment>
                  );
                })}

              {/* ----------------- ADDITIONALS ---------------- */}
              {additionalsAvailable.length > 0 && <h4>Elige tus adiciones</h4>}
              {additionalsAvailable.map((currentAdditional) => {
                return (
                  <Additional
                    key={currentAdditional._id}
                    currentAdditional={currentAdditional}
                    additionals={additionals}
                    setAdditionals={setAdditionals}
                    subProducts={subProducts}
                    setTotalOfTheAdditionals={setTotalOfTheAdditionals}
                    totalOfTheMainProduct={totalOfTheMainProduct}
                    setTotalOfTheProduct={setTotalOfTheProduct}
                  />
                );
              })}

              {functionality && (
                <>
                  <h4>Comentarios</h4>
                  <div className="textArea">
                    <textarea
                      placeholder="Añade instrucciones especiales..."
                      value={description}
                      onChange={handlerTextArea}
                    ></textarea>
                  </div>
                </>
              )}
            </div>
          </Customization>
          <ContainerCount backgroundColor={backgroundColor}>
            <MainProductCounter
              initialValue={count}
              updateValue={changeQuantityOfTheMainProduct}
            />
            <Btn
              onClick={
                disabledButton
                  ? null
                  : !functionality
                  ? null
                  : aggregationController
              }
              btnColor={btnColor}
              disabled={disabledButton}
            >
              {functionality && <p>Agregar</p>}
              <p>
                {totalOfTheProduct?.toLocaleString(configCurrency.bcp47, {
                  style: "currency",
                  currency: configCurrency.iso_4217,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            </Btn>
          </ContainerCount>
        </ContainerModal>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .modal {
    width: 100%;
    height: 100%;
    padding: 2% 0;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: var(--color-Back-Gray);
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    @media (max-width: 1023px) {
      padding: 20px;
    }
  }
  .modal-open {
    display: flex;
  }
`;

const ContainerModal = styled.div`
  width: 850px;
  height: 80vh;
  max-height: 600px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-Back-Yelow)"};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
    border-radius: 10px;
    flex-direction: column;
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 4px 20px;
  padding-left: 5px;
  font-size: 0.8rem;
  color: ${(props) =>
    props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
`;

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1px;
  left: -1rem;
  border: 1px solid var(--color-Gray);
  background-color: var(--color-White);
  border-radius: 0.2em;

  ${Input}:not(:disabled):checked & {
    background: #e7e7e7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + & {
    background-color: var(--color-Gray);
    border-color: var(--color-Gray);
  }

  ${Input}:checked + &::after {
    display: block;
    top: 1px;
    left: 5px;
    width: 25%;
    height: 50%;
    border: solid var(--color-White);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Image = styled.div`
  width: 50%;
  height: 100%;
  padding: 40px 0 40px 40px;
  @media (max-width: 767px) {
    width: 100%;
    flex: ${(props) => `${props.scroll}`};
    padding: 0;
    position: relative;
    transition: 0.4s;
    overflow: hidden;
  }
  .close {
    display: none;
    @media (max-width: 767px) {
      display: flex;
      position: absolute;
      right: 20px;
      top: 20px;
      background-color: var(--color-White);
      border-radius: 50px;
      padding: 3px;
      box-shadow: 0px 5px 10px 5px rgb(0 0 0 / 5%);
    }
  }
  .image {
    background-image: ${(props) =>
      props.image === undefined
        ? `url(${restobarIcon})`
        : `url(${props.image})`};
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-Green-2);
    background-size: cover;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    @media (max-width: 767px) {
      border-radius: 10px 10px 0 0;
    }
  }
  .nameProduct {
    visibility: hidden;
    padding: 20px 20px 10px;
    position: absolute;
    width: 100%;
    color: white;
    transition: 0.3s;
    backdrop-filter: blur(0.3px);
    background: rgb(97, 97, 97);
    background: linear-gradient(
      0deg,
      rgba(97, 97, 97, 0.8) 0%,
      rgba(97, 97, 97, 0) 100%
    );
    @media (max-width: 767px) {
      visibility: ${(props) => (props.scroll2 > 40 ? "visible" : "hidden")};
      opacity: ${(props) => (props.scroll2 > 40 ? 1 : 0)};
      bottom: ${(props) => `  ${props.scroll2 > 40 ? 0 : -15}px`};
    }
  }
`;

const Customization = styled.div`
  width: 50%;
  height: 100%;
  padding-left: 1rem;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
    flex: 1;
    margin-bottom: 70px;
    overflow: auto;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    @media (max-width: 767px) {
      padding: 0;
    }
    > h4 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 1.2rem;
      color: ${(props) =>
        props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      .sale {
        padding: 0.5rem;
        margin-left: 0.3rem;
        background-color: ${(props) =>
          props.btnColor ? props.btnColor : "var(--color-Green)"};
        display: flex;
        align-items: center;
        border-radius: 30px;
        > p {
          font-size: 0.7rem;
          color: var(--color-White);
          padding: 0 0.4rem 0 0;
          margin-top: 1px;
        }
        > svg {
          height: 1rem;
        }
      }
      @media (max-width: 767px) {
        font-size: 1rem;
        line-height: 1;
      }
    }
    .close {
      &:hover {
        cursor: pointer;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .containerChecks {
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 40px;
    @media (max-width: 767px) {
      overflow: initial;
      padding-right: 0;
    }
    .description {
      font-size: 0.8rem;
      color: var(--color-Gray-2);
      padding: 5px 0;
    }
    .description_variable_price {
      font-size: 0.7rem;
      color: var(--color-Gray-2);
      padding: 5px 0;
      font-style: italic;
    }
    > h4 {
      font-size: 1rem;
      color: ${(props) =>
        props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      @media (max-width: 767px) {
        font-size: 0.8rem;
      }
      > span {
        font-size: 0.6rem;
        font-weight: 300;
      }
    }
    .textArea {
      display: flex;
      flex-direction: column;
      width: 100%;
      > textarea {
        border-color: var(--color-Gray-2);
        border: 1px solid var(--color-Gray-2);
        background-color: var(--color-White);
        border-radius: 8px;
        padding: 0.5rem 0.75rem;
        font-size: 0.8rem;
        margin: 5px 0;
        min-height: 100px;
        resize: none;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
`;

const Select = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: 100%;
  > label {
    font-size: 1rem;
    @media (max-width: 767px) {
      font-size: 0.8rem;
    }
    span {
      color: var(--color-Orange);
    }
  }
  > select {
    border-color: var(--color-Line);
    border: 1px solid var(--color-Gray);
    background-color: var(--color-White);
    border-radius: 8px;
    padding: 1rem 0.75rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
  }
  > span {
    color: var(--color-Orange);
    font-size: 0.8rem;
  }
`;

const ContainerCount = styled.div`
  width: calc(50% - 55px);
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    padding: 10px 20px;
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : "var(--color-Back-Yelow)"};
    right: initial;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
`;

const ContainerOptionCounter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > p {
    font-size: 0.8rem;
    color: "var(--color-Gray)";
  }
`;

const CountOptions = styled.div`
  min-width: 100px;
  height: 40px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    > svg:hover {
      cursor: pointer;
    }
  }
  > p {
    text-align: center;
    font-size: 0.9rem;
    padding: 0 8px 0 8px;
  }
  .hidden {
    opacity: 0;
    & svg:hover {
      cursor: auto;
    }
  }
`;

const Sales = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > h5 {
    margin: 0 0.3rem;
    font-size: 0.6rem;
    color: ${(props) =>
      props.btnColor ? props.btnColor : "var(--color-Green)"};
  }
`;

const Btn = styled.div`
  /* padding: 1rem; */
  width: 65%;
  height: 40px;
  max-width: 220px;
  background-color: ${(props) =>
    props.btnColor ? props.btnColor : "var(--color-Green)"};
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  opacity: ${(props) => props.disabled && "0.5"};
  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  > p {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-White);
    @media (max-width: 767px) {
      font-size: 0.7rem;
    }
    margin: auto;
  }
`;
