import React from "react";
import styled from "styled-components";
/*icon */
import iconoClose from "../../assets/images/icons/close.svg";
/*image-hands */
import imagenHands from "../../assets/images/handsThanks.svg";

const ModalThanks = ({
  isOpen,
  closeModal,
  setRedirectMenu,
  btnColor,
  lblTextColor,
  backgroundColor,
  configPayU,
  card,
}) => {
  // Redirect to menu
  const handleClick = () => {
    // closeModal();
    setRedirectMenu(true);
  };

  return (
    <Container>
      <div className={`modal ${isOpen && "modal-open"}`}>
        <ContainerModal
          btnColor={btnColor}
          lblTextColor={lblTextColor}
          backgroundColor={backgroundColor}
        >
          {configPayU && card ? (
            <></>
          ) : (
            <img
              className="close"
              src={iconoClose}
              alt="cerrar"
              onClick={() => {
                closeModal();
              }}
            />
          )}
          <img className="hands" src={imagenHands} alt="hands"></img>
          <h1>¡Gracias!</h1>
          {configPayU && card ? (
            <p>Estas siendo redirigido a la plataforma de pagos PayU...</p>
          ) : (
            <p>Tu pedido ha sido enviado</p>
          )}
          {configPayU && card ? (
            <></>
          ) : (
            <Btn onClick={handleClick} btnColor={btnColor}>
              Continuar Comprando
            </Btn>
          )}
        </ContainerModal>
      </div>
    </Container>
  );
};

export default ModalThanks;

const Container = styled.div`
  .modal {
    width: 100%;
    height: 100%;
    padding: 2% 0;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: var(--color-Back-Gray);
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  .modal-open {
    display: flex;
  }
`;

const ContainerModal = styled.div`
  width: 450px;
  height: auto;
  max-height: 600px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-Back-Yelow)"};
  border-radius: 1rem;
  padding: 40px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    min-height: 200px;
  }

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 767px) {
      top: 1rem;
      right: 1rem;
    }
  }

  .hands {
    width: 180px;
    @media (max-width: 767px) {
      width: 120px;
    }
  }

  > h1 {
    font-size: 3rem;
    color: ${(props) =>
      props.btnColor ? props.btnColor : "var(--color-Green)"};
    margin: 1.2rem 0;
    padding: 0 20px;
    text-align: center;
    line-height: 2.2rem;
    @media (max-width: 767px) {
      font-size: 2rem;
      line-height: 1.5rem;
    }
  }
  > p {
    font-size: 0.8rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const Btn = styled.div`
  padding: 1rem;
  width: 100%;
  height: 40px;
  background-color: ${(props) =>
    props.btnColor ? props.btnColor : "var(--color-Green)"};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-White);
  @media (max-width: 767px) {
    font-size: 0.7rem;
  }
  &:hover {
    cursor: pointer;
  }
`;
