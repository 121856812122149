import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";

import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";

/*component modal */
import ModalThanks from "./ModalThanks";
import ModalPayment from "./ModalPayment";

export default function Form({
  business,
  DATA_PRODUCTS,
  inside,
  deliveryFormState,
  setRedirectMenu,
  isOpenThanks,
  setIsOpenThanks,
  phoneNumber,
  // total,
  payu,
  changeAmount,
  getDataWebCheckoutPayu,
  setDeliveryForm,
  toggleModalRegister,
}) {
  const {
    token,
    user,
    userState,
    // setUserState,
    location,
    searchUser,
    businessData,
    subTotal,
    setShoppingCart,
    configDeliveryCost = 0,
    isOpenPayment,
    // setIsOpenPayment,
    urlQueries,
    configCurrency,
    configPayU,
    // reset,
    setReset,
    transactionState,
    toggleModalPayment,
    beforeOrderId,
    setBeforeOrderId,
  } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  const {
    backgroundColor = "",
    btnPrimaryColor: btnColor = "",
    lblTextColor = "",
  } = businessData.config?.style?.digitalMenu ?? {};

  /* ---------- Rect-hook-form ---------- */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* Hide modal thanks && return to menu view */
  const toggleModalThanks = () => {
    setIsOpenThanks(!isOpenThanks);
  };

  /* ---------- Form autofilled ---------- */
  const [modifiedForm, setModifiedForm] = React.useState(false); // Update user's info
  const [stateForm, setStateForm] = React.useState({
    name: user.name || "", // Last name ¿register?
    email: user.email || "",
    number: user.phone ? user.phone.substr(3) : phoneNumber,
    city:
      user.addresses && user.addresses[0] && user.addresses[0].cityName
        ? user.addresses[0].cityName
        : "", // Array ¿?
    district: "", // ¿register? (1)
    address:
      user.addresses && user.addresses[0] && user.addresses[0].line1
        ? user.addresses[0].line1
        : "", // Array ¿?
    comments:
      user.addresses && user.addresses[0] && user.addresses[0].notes
        ? user.addresses[0].notes
        : "", // Array ¿?
    payment: "", // ¿register? (1)
  });

  const handleChange = (e) => {
    if (e.target.name === "number") {
      //? library number type of data
      setStateForm({
        ...stateForm,
        [e.target.name]: Number(e.target.value),
      });
    } else {
      setStateForm({
        ...stateForm,
        [e.target.name]: e.target.value,
      });
    }

    // Form modified
    if (e.target.name !== "payment" || e.target.name !== "district") {
      // ¿register? (1)
      setModifiedForm(true);
    }
  };

  /* ---------- Business data sent in order ---------- */
  const BUSINESS = {
    externalId: business,
    name: businessData.name,
    score: 0, // ¿? ---- Not working
    cityDetail: {
      cityCode:
        businessData.cityDetail && businessData.cityDetail.cityCode
          ? businessData.cityDetail.cityCode
          : "",
      cityName:
        businessData.cityDetail && businessData.cityDetail.cityName
          ? businessData.cityDetail.cityName
          : "",
      countryCode:
        businessData.cityDetail && businessData.cityDetail.countryCode
          ? businessData.cityDetail.countryCode
          : "Co",
      countryName:
        businessData.cityDetail && businessData.cityDetail.countryName
          ? businessData.cityDetail.countryName
          : "Colombia",
      stateCode:
        businessData.cityDetail && businessData.cityDetail.stateCode
          ? businessData.cityDetail.stateCode
          : "",
      stateName:
        businessData.cityDetail && businessData.cityDetail.stateName
          ? businessData.cityDetail.stateName
          : "",
    },
    address: businessData.address,
    urlImage: businessData.urlImage
      ? businessData.urlImage
      : `${process.env.REACT_APP_IMG_BUCKET_BASE_URL}`,
    tradeName: businessData.tradeName || "",
  };

  /* ---------- Register or update user ---------- */
  const registerOrUpdateUser = async (data) => {
    // console.log(data);

    const USER = {
      addressDefault: {
        cityCode: "",
        cityName: data.city,
        countryCode: "Co", // ¿Country code? default
        countryName: "Colombia", // Default
        line1: data.address,
        neighborhood: data.district, // Neighborhood (New field ?)
        notes: data.comments,
        stateCode: "",
        stateName: "", // ¿?
      },
      addresses: [
        {
          cityCode: "",
          cityName: data.city,
          countryCode: "Co", // ¿Country code? default
          countryName: "Colombia", // Default
          line1: data.address,
          neighborhood: data.district, // Neighborhood (New field ?)
          notes: data.comments,
          stateCode: "",
          stateName: "", // ¿?
        },
      ],
      createdOn: new Date(), // Updated any time when the user is modified
      email: data.email,
      lastName: "", // ¿?
      location: {
        // Changing when the user is updated
        coordinates: [location.longitude || "", location.latitude || ""],
        type: "Point", // Set in backend
      },
      name: data.name,
      phone: "+57" + data.number,
      tokenCurrent: token,
    };

    // console.log(USER);

    // Register and update have the same endpoint
    await axios
      .post(process.env.REACT_APP_CURRENT_USER_URL, USER, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(async (res) => {
        // console.log(res);
        if (res.status === 200) {
          await searchUser(token);
          // Send order
          await sendOrder(data);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response?.data?.message);

        if (
          error.response?.data?.message === "Unauthorized" ||
          error.response?.data?.message === null
        ) {
          throw new Error("UNAUTHORIZED");
        }
      });
  };

  /* ---------- Send order ---------- */
  const [orderId, setOrderId] = React.useState("");

  function cleanOrder(order) {
    let items = order.cart.items.map((product) => {
      let productsCombo = product.productsCombo.filter(
        (combo) => combo.qty > 0
      );
      product.productsCombo = productsCombo;
      return product;
    });
    order.cart.items = items;
    return order;
  }

  const sendOrder = async (data) => {
    console.log("Order sent");
    //
    // Cart data sent in order
    const CART = [];
    await DATA_PRODUCTS.forEach((product) => {
      const COMBO = [];
      for (let combo in product.combo) {
        COMBO.push(product.combo[combo]);
      }

      CART.push({
        externalId: product.externalId,
        name: product.name,
        qty: product.quantity,
        price: product.price,
        total: product.subTotal,
        productsCombo: COMBO,
        extras: product.extras,
        notes: product.notes,
        waiterOrderArea: product.waiterOrderArea,
        promo: product.promo ?? {},
      });
    });

    const ORDER = {
      business: { ...BUSINESS },
      user: {
        name: data.name,
        phone: data.number, // Sending phone in order (Not working in version 1)
        address: {
          notes: data.comments, // Comments in form (*)
          cityName: data.city,
          stateName: "", // Bogotá D.C" ¿? ---- Not working,
          cityCode: "", // "11001" ¿? ---- Not working
          countryCode: "Co", // Default
          stateCode: "", //11" ¿? ---- Not working
          countryName: "Colombia", // Default
          line1: data.address + ", " + (data.district ?? ''), // Address
        },
        email: data.email,
      },
      type:
        urlQueries.type && urlQueries.type === "INSIDE_RESTAURANT"
          ? "INSIDE_RESTAURANT"
          : "DELIVERY_BY_RESTAURANT",
      cart: {
        items: CART, // Shopping cart
        deliveryCost:
          urlQueries.type && urlQueries.type === "INSIDE_RESTAURANT"
            ? 0
            : deliveryFormState === "inSite"
            ? 0
            : configDeliveryCost,
      },
      location: {
        coordinates: [location.longitude || "", location.latitude || ""],
        type: "Point", // Set in backend
      },
      notes: data.comments, // Comments in form (*)
      tableId: urlQueries.tableId ? urlQueries.tableId : "",
      orderPaymentMethod: data.payment,
      currentStatus: configPayU && card ? "WAITING_FOR_PAYMENT" : "NEW_ORDER",
      deliveryMethod: deliveryFormState === "inSite" ? "PICKUP" : "",
    };
    let orderClean = cleanOrder(ORDER);
    // Post order
    await axios
      .post(process.env.REACT_APP_SEND_ORDER_URL, orderClean, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(async (res) => {
        console.log(res);
        if (res.status === 200) {
          console.log("Request sent to business");
          if (res.data && res.data.order && res.data.order._id) {
            setOrderId(res.data.order._id);
            setBeforeOrderId(res.data.order._id);
          } else {
            console.log("Order id not found");
          }
          //
          if (configPayU && card) {
            toggleModalThanks();
            // Waiting time to redirect to Payu
            await timeToRedirectToPayu();
            // Payment webCheckout PayU
            await submitPayUForm();
          } else {
            toggleModalThanks();
            // Reset shopping cart
            setShoppingCart({});
            //
            setSendingOrder(false);
            console.log(res.data.order._id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(
          "No se ha podido realizar el pedido con éxito, intenta nuevamente"
        );
        console.log(error.message);
        console.log(error.response?.data?.message);
        setSendingOrder(false);

        if (
          error.response?.data?.message === "Unauthorized" ||
          error.response?.data?.message === null
        ) {
          throw new Error("UNAUTHORIZED");
        }
      });
  };

  /* ---------- Logic sending order ---------- */
  const [sendingOrder, setSendingOrder] = React.useState(false);
  // Handle submit
  const onSubmit = async (formData) => {
    const data = {...formData};

    if (!data.number || typeof data.number !== 'number') {
      const phone = user.phone ? user.phone.substr(3) : phoneNumber;
      data.number = Number(phone);
    }

    const minimumOrder =
      businessData?.config?.externalIntegration?.domiPirPos?.minimumOrder ?? 0;
    if (minimumOrder <= subTotal) {
      // data-form
      // Sending ....
      setSendingOrder(true);
      //
      try {
        if (!userState) {
          // The user is not registered
          console.log("userState is false");
          // Register user && send order
          await registerOrUpdateUser(data);
          //
        } else if (userState && modifiedForm) {
          // Already there is a user registered, but it will be modified
          console.log("userState is true, modified user");
          // Update user && send order
          await registerOrUpdateUser(data);
          //
        } else {
          console.log("userState is true");
          // Already there is a user registered
          await sendOrder(data);
        }
      } catch (error) {
        console.log(error);
        if (error.message === "UNAUTHORIZED") {
          // console.log("POS SI");
          toggleModalRegister();
        }
      }
    } else {
      toast.error(
        `El valor minimo de la orden es de ${minimumOrder.toLocaleString(
          configCurrency.bcp47,
          {
            style: "currency",
            currency: configCurrency.iso_4217,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        )}`
      );
    }
  };

  /* ----------------- Handle payment payu integration ----------------- */
  const [card, setCard] = React.useState(false); // true
  const [paymentMethodChecked, setPaymentChecked] = React.useState("Cash"); // Online //! payload ?
  const payuForm = React.useRef();

  const handlePayment = (e) => {
    if (e.target.value === "Online") {
      // Option actives the payu method
      setCard(true);
    } else {
      setCard(false);
    }

    setPaymentChecked(e.target.value);
  };

  const submitPayUForm = async (retryPayment) => {
    // Get data if amount changed
    if ((configPayU && changeAmount && card) || retryPayment) {
      await getDataWebCheckoutPayu();
    }
    // Redirect to payU
    if ((configPayU && card) || retryPayment) {
      // Reset shopping cart
      await setReset(true);
      payuForm.current.submit();
    }
  };

  const timeToRedirectToPayu = async () => {
    setTimeout(() => {
      console.log("Redirect to Payu");
    }, 4000);
  };

  // response
  // transactionState
  // 4 === APPROVED
  // 6 === REJECTED
  // 104 === ERROR
  // 5 === EXPIRED
  // 7 === PENDING

  const merchantId = React.useRef();
  const accountId = React.useRef();
  const description = React.useRef();
  const referenceCode = React.useRef();
  const amount = React.useRef();
  const tax = React.useRef();
  const taxReturnBase = React.useRef();
  const currency = React.useRef();
  const signature = React.useRef();
  const test = React.useRef();
  const email = React.useRef();
  const responseUrl = React.useRef();
  const confirmationUrl = React.useRef();
  const extra1 = React.useRef();

  if (extra1.current) {
    merchantId.current.value = payu.merchantId;
    accountId.current.value = payu.accountId;
    description.current.value = payu.description;
    referenceCode.current.value = payu.referenceCode;
    amount.current.value = payu.amount;
    tax.current.value = payu.tax;
    taxReturnBase.current.value = payu.taxReturnBase;
    currency.current.value = payu.currency;
    signature.current.value = payu.signature;
    test.current.value = payu.test;
    email.current.value = stateForm.email;
    responseUrl.current.value = `${process.env.REACT_APP_RESPONSE_PAYU_BASE_URL}/shopping-cart/${business}`;
    confirmationUrl.current.value = payu.confirmationUrl;
    extra1.current.value = orderId ? orderId : beforeOrderId;
  }

  return (
    <ContainerForm
      lblTextColor={lblTextColor}
      backgroundColor={backgroundColor}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5>Datos de entrega</h5>
        {urlQueries["type"] !== "INSIDE_RESTAURANT" && (
          <div className="delivery">
            <div>
              <label htmlFor="delivery_form_delivery">Domicilio</label>
              <input
                id="delivery_form_delivery"
                type="radio"
                name="delivery"
                value="delivery"
                onChange={setDeliveryForm}
                checked={deliveryFormState === "delivery" ? true : false}
              />
            </div>
            <div>
              <label htmlFor="delivery_form_in_site">Recoger</label>
              <input
                id="delivery_form_in_site"
                type="radio"
                name="delivery"
                value="inSite"
                onChange={setDeliveryForm}
                checked={deliveryFormState === "inSite" ? true : false}
              />
            </div>
          </div>
        )}
        <div className="containerRow-2">
          <InputText className="input" lblTextColor={lblTextColor}>
            <label>
              Nombre<span>*</span>
            </label>
            <input
              placeholder="ej. Pablo Pérez"
              {...register("name", { required: true })}
              value={stateForm["name"]}
              onChange={handleChange}
            />
            {errors.name && errors.name.type === "required" && (
              <span>*Campo vacío</span>
            )}
          </InputText>
          {!inside && deliveryFormState === "delivery" && (
            <InputText className="input" lblTextColor={lblTextColor}>
              <label>
                Correo<span>*</span>
              </label>
              <input
                placeholder="ej. minombre@correo.com"
                {...register("email", {
                  required: { value: true, message: "*campo vacio" },
                  pattern: /^\S+@\S+$/i,
                })}
                type="text"
                value={stateForm["email"]}
                onChange={handleChange}
              />
              {errors.email && errors.email?.message && (
                <span>{errors.email?.message}</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span>*Ingrese un correo valido.</span>
              )}
            </InputText>
          )}
          <InputText className="input" lblTextColor={lblTextColor}>
            <label>
              Número telefónico<span>*</span>
            </label>
            <input
              placeholder="ej. 3134560019"
              type="number"
              {...register("number", {
                // required: true,
                maxLength: 10,
                minLength: 10,
              })}
              value={Number(stateForm["number"])}
              onChange={handleChange}
              disabled={true}
            />
            {errors.number && errors.number.type === "required" && (
              <span>*Campo vacío</span>
            )}
            {errors.number && errors.number.type === "maxLength" && (
              <span>*Máximo 10 números</span>
            )}
            {errors.number && errors.number.type === "minLength" && (
              <span>*Mínimo 10 números</span>
            )}
          </InputText>
        </div>
        {!inside && deliveryFormState === "delivery" && (
          <div className="containerRow">
            <InputText className="input" lblTextColor={lblTextColor}>
              <label>
                Ciudad<span>*</span>
              </label>
              <input
                placeholder="ej. Cali"
                {...register("city", { required: true })}
                value={stateForm["city"]}
                onChange={handleChange}
              />
              {errors.city && errors.city.type === "required" && (
                <span>*Campo vacío</span>
              )}
            </InputText>
            {/* <InputText className="input" lblTextColor={lblTextColor}>
              <label>
                Barrio<span>*</span>
              </label>
              <input
                placeholder="ej. Ciudad Jardín"
                {...register("district", { required: true })}
                value={stateForm['district']}
                onChange={handleChange}
              />
              {errors.district && errors.district.type === "required" && (
                <span>*Campo vacío</span>
              )}
            </InputText> */}
          </div>
        )}
        <div className="containerRow-2">
          {!inside && deliveryFormState === "delivery" && (
            <InputText className="input" lblTextColor={lblTextColor}>
              <label>
                Dirección<span>*</span>
              </label>
              <input
                placeholder="ej. Carrera 24 No 34 - 21"
                {...register("address", { required: true })}
                value={stateForm["address"]}
                onChange={handleChange}
              />
              {errors.address && errors.address.type === "required" && (
                <span>*Campo vacío</span>
              )}
            </InputText>
          )}
          <TextArea lblTextColor={lblTextColor}>
            <label>Comentarios</label>
            <textarea
              placeholder="Añade instrucciones especiales..."
              {...register("comments", { required: false })}
              value={stateForm["comments"]}
              onChange={handleChange}
            ></textarea>
          </TextArea>
        </div>
        <Line />
        <div className="containerChecks">
          <p>Medio de pago</p>
          <div>
            {/* {configPayU && (
              <Label lblTextColor={lblTextColor}>
                En linea
                <Input
                  type="radio"
                  value="Online" // Online
                  {...register("payment", { required: true })}
                  onChange={handlePayment}
                  checked={paymentMethodChecked === "Online" ? true : false}
                />
                <Indicator />
              </Label>
            )} */}
            {(!businessData?.config?.externalIntegration?.domiPirPos?.acceptCard ||
              businessData?.config?.externalIntegration?.domiPirPos?.acceptCash) && (
              <Label lblTextColor={lblTextColor}>
                Efectivo
                <Input
                  type="radio"
                  value="Cash"
                  {...register("payment", { required: true })}
                  onChange={handlePayment}
                  checked={paymentMethodChecked === "Cash" ? true : false}
                />
                <Indicator />
              </Label>
            )}
            {businessData?.config?.externalIntegration?.domiPirPos
              ?.acceptCard && (
              <Label lblTextColor={lblTextColor}>
                Tarjeta en sitio
                <Input
                  type="radio"
                  value="Card" // Tarjeta
                  {...register("payment", { required: true })}
                  onChange={handlePayment}
                  checked={paymentMethodChecked === "Card" ? true : false}
                />
                <Indicator />
              </Label>
            )}
          </div>
          {errors.payment && errors.payment.type === "required" && (
            <span>*Seleccione un medio de pago</span>
          )}
        </div>
        {businessData?.config?.externalIntegration?.domiPirPos?.isOpen ? (
          <Btn type="submit" disabled={sendingOrder} btnColor={btnColor}>
            <p>
              {sendingOrder && !card
                ? "Enviando orden ...."
                : configPayU && card
                ? "Pagar"
                : "Efectuar pedido"}
            </p>
          </Btn>
        ) : (
          <Btn type="button" disabled={true} btnColor={"gray"}>
            <p>El negocio se encuentra cerrado</p>
          </Btn>
        )}
      </form>

      {/* -------------- Payu form webCheckout integration ------------ */}
      <form
        method="post"
        action={process.env.REACT_APP_PAYU_WEB_CHECKOUT_URL}
        ref={payuForm}
      >
        <input name="merchantId" type="hidden" ref={merchantId} />
        <input name="accountId" type="hidden" ref={accountId} />
        <input name="description" type="hidden" ref={description} />
        <input name="referenceCode" type="hidden" ref={referenceCode} />
        <input name="amount" type="hidden" ref={amount} />
        <input name="tax" type="hidden" ref={tax} />
        <input name="taxReturnBase" type="hidden" ref={taxReturnBase} />
        <input name="currency" type="hidden" ref={currency} />
        <input name="signature" type="hidden" ref={signature} />
        <input name="test" type="hidden" ref={test} />
        <input name="buyerEmail" type="hidden" ref={email} />
        <input name="responseUrl" type="hidden" ref={responseUrl} />
        <input name="confirmationUrl" type="hidden" ref={confirmationUrl} />
        <input name="extra1" type="hidden" ref={extra1} />
      </form>

      <ModalThanks
        isOpen={isOpenThanks}
        closeModal={toggleModalThanks}
        setRedirectMenu={setRedirectMenu}
        btnColor={btnColor}
        lblTextColor={lblTextColor}
        backgroundColor={backgroundColor}
        configPayU={configPayU}
        card={card}
      />

      <ModalPayment
        isOpen={isOpenPayment}
        closeModal={toggleModalPayment}
        setRedirectMenu={setRedirectMenu}
        btnColor={btnColor}
        lblTextColor={lblTextColor}
        backgroundColor={backgroundColor}
        transactionState={transactionState}
        setShoppingCart={setShoppingCart}
        setSendingOrder={setSendingOrder}
        submitPayUForm={submitPayUForm}
        setReset={setReset}
      />
    </ContainerForm>
  );
}

const ContainerForm = styled.div`
  width: 100%;
  padding: 30px 20px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-Back-Yelow)"};
  > form h5 {
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    font-size: 1.2rem;
  }
  .containerRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .input,
    .select,
    .checkbox {
      width: 49%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .containerRow-2 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .input,
    .select {
      width: 100%;
    }
  }
  .containerChecks {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0.5rem;
    @media (max-width: 374px) {
      height: 100px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 374px) {
        align-items: flex-start;
        flex-direction: column;
      }
      > p {
        font-size: 0.8rem;
        font-weight: 700;
        color: ${(props) =>
          props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      }
    }
    > p {
      font-size: 0.8rem;
      padding: 4px 0;
    }
    > span {
      color: var(--color-Orange);
      font-size: 0.8rem;
    }
  }
  .delivery {
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    color: ${(props) => props.lblTextColor ?? "inherit"};
    align-items: center;
  }
  .delivery div {
    margin: auto;
  }
  .delivery div label {
    padding: 4px;
  }
  .disabled {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  > label {
    font-size: 0.8rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
  }
  > textarea {
    border-color: var(--color-Gray-2);
    border: 1px solid var(--color-Gray-2);
    background-color: var(--color-White);
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    margin: 5px 0;
    min-height: 100px;
    resize: none;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: var(--color-Place-Holder);
    }
  }
`;

const InputText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100px;
  > label {
    font-size: 0.8rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    span {
      color: var(--color-Orange);
    }
  }
  > input {
    border-color: var(--color-Gray);
    border: 1px solid var(--color-Gray);
    border-radius: 8px;
    padding: 0.75rem 0.75rem;
    font-size: 0.8rem;
    margin: 5px 0;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: var(--color-Place-Holder);
    }
  }
  > span {
    color: var(--color-Orange);
    font-size: 0.8rem;
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 4px 20px;
  padding-left: 5px;
  font-size: 0.8rem;
  color: ${(props) =>
    props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
`;

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1px;
  left: -1.5em;
  border: 1px solid var(--color-Gray);
  background-color: var(--color-White);
  border-radius: 0.2em;
  ${Input}:not(:disabled):checked & {
    background: #e7e7e7;
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
  ${Input}:checked + & {
    background-color: var(--color-Gray);
    border-color: var(--color-Gray);
  }
  ${Input}:checked + &::after {
    display: block;
    top: 1px;
    left: 4px;
    width: 25%;
    height: 50%;
    border: solid var(--color-White);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-Gray);
`;

const Btn = styled.button`
  padding: 1rem;
  margin-top: 20px;
  width: 100%;
  height: 40px;
  background-color: ${(props) =>
    props.btnColor ? props.btnColor : "var(--color-Green)"};
  border-radius: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  > p {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-White);
    @media (max-width: 767px) {
      font-size: 0.7rem;
    }
  }
`;
