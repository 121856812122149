import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

/*icon */
// import IconShoppingCar from "../../assets/images/icons/shopping-car";
// import IconLogOut from "../../assets/images/icons/log-out.svg";

export default function NavBar({
  business,
  categories,
  categoryClick,
  // carActive,
  // quantity = 0,
  categorySelected,
  // closeModal,
  // session,
  urlExtension,
  btnColor,
  backgroundColor,
  lblTextColor,
  // logOut,
}) {
  return (
    <ContainerGeneral
      backgroundColor={backgroundColor}
      lblTextColor={lblTextColor}
    >
      <div className="containerHead">
        {/* <h1>Menú</h1> */}
        {/* <ContainerBtn color={btnColor}>
          {session ? (
            <Link
              className="car"
              to={`/shopping-cart/${business}${urlExtension}`}
            >
              <IconShoppingCar color={carActive && btnColor} />
              {carActive && <p>{quantity}</p>}
            </Link>
          ) : (
            <div
              className="car"
              onClick={() => {
                closeModal();
              }}
            >
              <IconShoppingCar color={carActive && btnColor} />
              {carActive && <p>{quantity}</p>}
            </div>
          )}
          {session && (
            <img
              className="logOut"
              src={IconLogOut}
              onClick={() => logOut()}
              alt="logOut"
            />
          )}
        </ContainerBtn> */}
      </div>
      <Nav btnColor={btnColor}>
        {categories.map((item, idx) => {
          return (
            <NavLink
              key={item.category._id}
              activeClassName="active"
              className={!categorySelected && idx === 0 ? "active" : ""}
              to={`/menu/${business}/${item.category.name}${urlExtension}`}
              data-index={idx}
              data-category={item.category._id}
              data-category_name={item.category.name}
              onClick={categoryClick}
            >
              <p
                data-index={idx}
                data-category={item.category._id}
                data-category_name={item.category.name}
              >
                {item.category.name}
              </p>
            </NavLink>
          );
        })}
      </Nav>
    </ContainerGeneral>
  );
}

const ContainerGeneral = styled.div`
  width: 100%;
  max-width: 1440px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-White)"};
  height: auto;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-around;
  position: sticky;
  top: 0;
  left: 0;
  @media (max-width: 1023px) {
    position: fixed;
    top: 80px;
    padding: 0;
  }
  @media (max-width: 1023px) and (orientation: landscape) {
    position: inherit;
  }
  .containerHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
    > h1 {
      color: ${(props) =>
        props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      line-height: 1;
      margin-bottom: 0.5rem;
      @media (max-width: 1023px) {
        padding-left: 20px;
      }
      @media (max-width: 767px) {
        padding-left: 0;
      }
    }
    > img:hover {
      cursor: pointer;
    }
  }
`;

/* const ContainerBtn = styled.div`
  display: flex;
  .car, .logOut {
    display: flex;
    align-items: center;
    transition: 0.2s;
    padding: 8px;
    border-radius: 5px;
    @media (max-width: 1023px) {
      display: none;
    }
    > p {
      margin-left: 5px;
      color: ${(props) => (props.color ? props.color : "var(--color-Green)")};
    }
  }
  .car:hover,
  .logOut:hover {
    background-color: var(--color-Back);
    cursor: pointer;
  }
  .logOut {
    margin-left: 0.5rem;
  }
`; */

const Nav = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  overflow: auto;
  overflow-y: hidden;
  @media (max-width: 1023px) {
    padding: 10px 20px;
    height: 60px;
    flex-wrap: initial;
  }
  > a {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    background-color: var(--color-Gray);
    color: var(--color-White);
    border-radius: 50px;
    font-weight: 300;
    margin: 2.5px 4px;
    outline: none;
    @media (max-width: 767px) {
      margin: 0 3px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    > p {
      color: var(--color-White);
      width: max-content;
      font-size: 0.8rem;
    }
  }
  .active {
    background-color: ${(props) =>
      props.btnColor ? props.btnColor : "var(--color-Green)"};
  }
`;
