import React from "react";

function shoppingCar({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <g
        stroke= {color ? color : "#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0)"
      >
        <path d="M7.5 18.833a.833.833 0 100-1.666.833.833 0 000 1.666zM16.667 18.833a.833.833 0 100-1.666.833.833 0 000 1.666zM.833 1.333h3.334L6.4 12.492a1.667 1.667 0 001.667 1.341h8.1a1.667 1.667 0 001.666-1.341L19.167 5.5H5"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default shoppingCar;
