import {
  estimateAvailabilityOfThePromotion,
  calculateQuantityToPay,
  calculatePriceToPay,
} from "../shared/evaluatePromotion";

export const findProductAndSubProductInMenu = (menu, productInShoppingCart) => {
  const categoryInMenu = menu.find(
    (e) => e.category?._id === productInShoppingCart.categoryId
  );
  const productInMenu = categoryInMenu?.products?.find(
    (e) => e.internalId?._id === productInShoppingCart.productId
  );
  let subProductInMenu;
  if (productInShoppingCart.subProductId) {
    subProductInMenu = productInMenu?.internalId?.subProducts?.find(
      (e) => e._id === productInShoppingCart.subProductId
    );
  }

  return { productInMenu, subProductInMenu };
};

export const getCurrentStock = (
  businessId,
  productInMenu,
  subProductInMenu
) => {
  if (subProductInMenu) {
    // Subproduct
    const [stock] = subProductInMenu?.locationsStock ?? [];
    return stock ?? {};
  } else {
    // Main product
    const stock = productInMenu?.internalId?.locationsStock?.find(
      (stock) => stock.locationStock?.business === businessId
    );
    return stock ?? {};
  }
};

const getVariablePrice = (productInMenu, combo) => {
  const typeVariablePrice = productInMenu.internalId?.variablePrice?.type;

  // If only there's a comboSelectionOne
  const comboSelectionOneUpdated = {
    ...combo,
  };

  const pricesOfCombo = [];

  for (let optionCombo in comboSelectionOneUpdated) {
    const option = productInMenu.internalId?.configCombo?.options.find(
      (option) => option._id === comboSelectionOneUpdated[optionCombo].optionId
    );

    const item = option?.products?.find(
      (item) =>
        item.product._id === comboSelectionOneUpdated[optionCombo].externalId
    );

    const price = item?.product?.locationsStock?.[0]?.price || 0;
    pricesOfCombo.push(price);

    comboSelectionOneUpdated[optionCombo].price = price;
  }

  let priceOfVariablePrice = 0;

  if (typeVariablePrice === "MayorValor") {
    const maxPrice = Math.max(...pricesOfCombo);
    priceOfVariablePrice = maxPrice;
  }

  if (typeVariablePrice === "MenorValor") {
    const minPrice = Math.min(...pricesOfCombo);
    priceOfVariablePrice = minPrice;
  }

  return priceOfVariablePrice;
};

export const updateProductInShoppingCart = (
  stock,
  qty,
  shoppingCart,
  productInShoppingCart,
  productInMenu
) => {
  // Delete product in Shopping cart if the product is not in the menu
  if (
    !productInMenu ||
    (productInMenu.internalId && !productInMenu.show) ||
    (productInMenu.internalId && !productInMenu.internalId?.isActive)
  ) {
    const shoppingCartUpdated = {
      ...shoppingCart,
    };

    delete shoppingCartUpdated[productInShoppingCart.id_shoppingCart];
    return shoppingCartUpdated;
  }

  const availablePromo = estimateAvailabilityOfThePromotion(stock.promo);

  const price = productInShoppingCart.variablePrice
    ? getVariablePrice(productInMenu, productInShoppingCart.combo)
    : stock.price || productInShoppingCart.price;

  const quantityToPay = calculateQuantityToPay(
    qty,
    stock.promo,
    availablePromo
  );

  const priceToPay = calculatePriceToPay(
    qty,
    stock.promo,
    price,
    availablePromo
  );

  //? Evaluate changes in additionals
  let totalOfTheAdditionals = 0;
  productInShoppingCart?.extras.forEach((extra) => {
    totalOfTheAdditionals += extra.price * extra.qty;
  });
  //? Evaluate changes in combo
  const shoppingCartUpdated = {
    ...shoppingCart,
    [productInShoppingCart.id_shoppingCart]: {
      ...shoppingCart[productInShoppingCart.id_shoppingCart],
      quantity: qty,
      price,
      subTotal: priceToPay * quantityToPay + totalOfTheAdditionals,
    },
  };

  // Promotion
  if (
    !availablePromo &&
    shoppingCartUpdated[productInShoppingCart.id_shoppingCart].promo
  ) {
    // Delete promo of product in shopping cart if the promo is not active
    delete shoppingCartUpdated[productInShoppingCart.id_shoppingCart].promo;
  } else if (availablePromo) {
    // Updating the active promo
    shoppingCartUpdated[productInShoppingCart.id_shoppingCart].promo = {
      priceFull: price * qty + totalOfTheAdditionals,
      discount: price * qty - priceToPay * quantityToPay,
      type: stock.promo?.type,
    };
  }

  return shoppingCartUpdated;
};
