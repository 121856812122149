import React from "react";
import styled from "styled-components";

export default function AllianceBanner({ btnColor, imageSrc }) {
  return <ContainerGeneral src={imageSrc} color={btnColor}></ContainerGeneral>;
}

const ContainerGeneral = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 500px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => props.color};
  @media (max-width: 1023px) {
    margin-top: 140px;
    height: 300px;
  }
  @media (max-width: 1023px) and (orientation: landscape) {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    height: 180px;
  }
`;
