import React from "react";
import styled from "styled-components";

import { MENU_SHOPPINGCART_CONTEXT } from "../../../context/MenuShoppingCartProvider";

export default function ComboSelectionOne({
  item,
  option,
  comboSelectionOne,
  setComboSelectionOne,
  variablePrice,
  setTotalOfTheProduct,
  combosUniqueOptions,
  setDisabledButton,
  totalOfTheAdditionals,
  setTotalOfTheMainProduct,
}) {
  const { businessData } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  const { btnPrimaryColor: btnColor = "" } =
    businessData.config?.style?.digitalMenu ?? {};

  const updateComboSelectionOne = (e) => {
    const comboSelectionOneUpdated = {
      ...comboSelectionOne,
      [e.target.name]: {
        optionName: e.target.name,
        optionId: e.target.dataset.option_id,
        externalId: e.target.dataset.id,
        name: e.target.dataset.name,
        qty: 1,
        price: Number(e.target.dataset.price),
      },
    };

    setComboSelectionOne(comboSelectionOneUpdated);

    if (variablePrice.isVariablePrice) {
      const prices = Object.values(comboSelectionOneUpdated).map(
        (product) => product.price
      );

      if (variablePrice.type === "MayorValor") {
        const maxPrice = Math.max(...prices);

        setTotalOfTheMainProduct(maxPrice);
        setTotalOfTheProduct(totalOfTheAdditionals + maxPrice);
      }

      if (variablePrice.type === "MenorValor") {
        const minPrice = Math.min(...prices);

        setTotalOfTheMainProduct(minPrice);
        setTotalOfTheProduct(totalOfTheAdditionals + minPrice);
      }
    }

    // Enable aggregate button
    let combosUniqueOptionsChecked = 0;
    // eslint-disable-next-line no-unused-vars
    for (let option in comboSelectionOne) {
      combosUniqueOptionsChecked++;
    }

    if (!comboSelectionOne[e.target.name]) {
      combosUniqueOptionsChecked++;
    }

    if (combosUniqueOptions === combosUniqueOptionsChecked) {
      setDisabledButton(false);
    }
  };

  return (
    <ContainerRadio>
      <Label>
        {item.product.name}
        <Input
          type="radio"
          name={option.name}
          data-id={item.product._id}
          data-name={item.product.name}
          data-price={item.product.locationsStock?.[0]?.price || 0}
          data-option_id={option._id}
          onChange={updateComboSelectionOne}
          checked={
            comboSelectionOne[option.name]?.["externalId"] ===
              item.product._id || false
          }
        />
        <IndicatorRadio color={btnColor} />
      </Label>
    </ContainerRadio>
  );
}

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const ContainerRadio = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0;
  height: 24px;
  padding-left: 3px;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 4px 20px;
  padding-left: 5px;
  font-size: 0.8rem;
  color: ${(props) =>
    props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
`;

const IndicatorRadio = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: -1.2rem;
  border: 2px solid var(--color-Gray);
  border-radius: 50%;

  ${Input}:not(:disabled):checked & {
    background: #e7e7e7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + & {
    border-color: var(--color-Gray);
  }

  ${Input}:checked + &::after {
    display: block;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.color ? props.color : "var(--color-Gray)"};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
