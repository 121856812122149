import React from "react";
import { useLocation } from "react-router";
import axios from "axios";

const MENU_SHOPPINGCART_CONTEXT = React.createContext();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Custom hook
function useLocalStorage(key, initialValue) {
  // Only executed once
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      // Save state
      setStoredValue(value);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // Try again ¿?
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

const MenuShoppingCartProvider = (props) => {
  /* ---------- Session's state && Token ---------- */
  const [session, setSession] = useLocalStorage("session", false);
  const [token, setToken] = useLocalStorage("token", "");
  const [number, setNumber] = useLocalStorage("number", "");

  /* ---------- User's state && data ---------- */
  const [userState, setUserState] = useLocalStorage("userState", false);
  const [user, setUser] = useLocalStorage("user", {});
  const [location, setLocation] = React.useState({});

  // User's location
  function getLocation() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geolocation is not supported by this browser");
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  function showPosition(position) {
    setLocation(position.coords);
  }

  /* ------------------ Free token --------------------- */
  const [freeToken, setFreeToken] = useLocalStorage("freeToken", false);
  const [functionality, setFunctionality] = useLocalStorage(
    "functionality",
    false
  );

  /* ---------- Shopping cart status ---------- */
  const [shoppingCart, setShoppingCart] = useLocalStorage("shoppingCart", {});
  // Category selected
  // It shows the first category until one category is selected
  const [categorySelected, setCategorySelected] = useLocalStorage(
    "categorySelected",
    {
      anySelected: false,
      selected: {
        products: [],
      },
    }
  );

  // Quantity of products
  let quantity = 0;
  let subTotal = 0;
  for (let product in shoppingCart) {
    quantity += shoppingCart[product]["quantity"];
    subTotal += shoppingCart[product]["subTotal"];
  }

  // Reset shopping cart
  const [reset, setReset] = useLocalStorage("reset", false);

  // Business Data
  const [menu, setMenu] = useLocalStorage("menu", []);
  const [businessData, setBusinessData] = useLocalStorage("businessData", {});

  const [configCurrency, setConfigCurrency] = useLocalStorage(
    "configCurrency",
    {
      countryCode: "Co",
      bcp47: "es-CO",
      iso_4217: "COP",
    }
  );

  const [configDeliveryCost, setConfigDeliveryCost] = useLocalStorage(
    "configDeliveryCost",
    0
  );

  const [configPayU, setConfigPayU] = useLocalStorage("configPayU", false);

  // User info
  const searchUser = async (tokenParam) => {
    //
    await axios
      .get(process.env.REACT_APP_CURRENT_USER_URL, {
        headers: {
          Authorization: "Bearer " + tokenParam,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // User's data
          setUser(res.data);
          // If a user is found, userState is true
          setUserState(true);
        }
      })
      .catch((error) => console.log(error)); //!
  };

  // Queries
  const queries = useQuery();
  const [urlQueries, setUrlQueries] = React.useState({});
  const [urlExtension, setUrlExtension] = React.useState("");

  /* Show modal payment */
  const [isOpenPayment, setIsOpenPayment] = React.useState(false);
  const [transactionState, setTransactionState] = React.useState(1);
  const [beforeOrderId, setBeforeOrderId] = useLocalStorage(
    "beforeOrderId",
    ""
  );

  /* Hide modal payment && return to menu view */
  const toggleModalPayment = () => {
    if (transactionState === 4 && reset && isOpenPayment) {
      setReset(false);
      setShoppingCart({});
    }

    setIsOpenPayment(!isOpenPayment);
  };

  React.useEffect(() => {
    getLocation();

    const Qs = {};
    queries.get("qrCode") && (Qs["qrCode"] = queries.get("qrCode"));
    queries.get("type") && (Qs["type"] = queries.get("type"));
    queries.get("tableId") && (Qs["tableId"] = queries.get("tableId"));

    let qrCode = "";
    let type = "";
    let tableId = "";
    Qs["qrCode"] && (qrCode = `?qrCode=${Qs["qrCode"]}`);
    Qs["type"] && (type = `&type=${Qs["type"]}`);
    Qs["tableId"] && (tableId = `&tableId=${Qs["tableId"]}`);

    if (qrCode) {
      setUrlExtension(qrCode + type + tableId);
    }

    setUrlQueries(Qs);

    // Reset shopping cart next time when menu or shoppingCart is visited after of doing a payment
    const paymentStatus =
      queries.get("transactionState") &&
      parseInt(queries.get("transactionState"));

    // Payu response state
    setTransactionState(paymentStatus);
    if (!paymentStatus && reset) {
      // Validation about payment status ¿?
      setReset(false);
      setShoppingCart({});
    }

    // APPROVED
    if (paymentStatus === 4 && reset) {
      toggleModalPayment();
      console.log("approved");
    }

    // REJECTED
    if (paymentStatus === 6 && reset) {
      toggleModalPayment();
      console.log("rejected");
    }
  }, []);

  /* ---------- Log out ---------- */
  const logOut = () => {
    // Restart user's info
    setSession(false);
    setToken("");
    setUserState(false);
    setUser({});
    setNumber("");
    setShoppingCart({});
    console.log("Session ended");
  };

  return (
    <MENU_SHOPPINGCART_CONTEXT.Provider
      value={{
        session,
        setSession,
        token,
        setToken,
        freeToken,
        setFreeToken,
        functionality,
        setFunctionality,
        number,
        setNumber,
        userState,
        setUserState,
        user,
        setUser,
        searchUser,
        location,
        shoppingCart,
        setShoppingCart,
        categorySelected,
        setCategorySelected,
        reset,
        setReset,
        quantity,
        subTotal,
        businessData,
        setBusinessData,
        menu,
        setMenu,
        configCurrency,
        setConfigCurrency,
        setConfigDeliveryCost,
        configDeliveryCost,
        configPayU,
        setConfigPayU,
        urlQueries,
        urlExtension,
        isOpenPayment,
        setIsOpenPayment,
        transactionState,
        toggleModalPayment,
        beforeOrderId,
        setBeforeOrderId,
        logOut,
      }}
    >
      {props.children}
    </MENU_SHOPPINGCART_CONTEXT.Provider>
  );
};

export { MenuShoppingCartProvider, MENU_SHOPPINGCART_CONTEXT };
