import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { MenuShoppingCartProvider } from "./context/MenuShoppingCartProvider";
import Menu from "./pages/Menu";
import ShoppingCar from "./pages/ShoppingCar";

import "./assets/global-styles/style.css";

function App() {
  return (
    <Router>
      <MenuShoppingCartProvider>
        <Route path="/menu/:id/:category?" component={Menu} />
        <Route exact path="/shopping-cart/:id" component={ShoppingCar} />
      </MenuShoppingCartProvider>
    </Router>
  );
}

export default App;
