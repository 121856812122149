import React from "react";
import styled from "styled-components";
import IconSales from "../../assets/images/icons/Sales";
import IconSales2x1 from "../../assets/images/icons/Sales2x1";
import restobarIcon from "../../assets/images/default-product-image.svg";

import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";
import DetailProduct from "./DetailProduct";

import { estimateAvailabilityOfThePromotion } from "../shared/evaluatePromotion";

export default function Product({ product }) {
  const {
    urlImage: image,
    name,
    description,
    locationsStock: mainStockLocation = [],
    subProducts = [],
  } = product.internalId ?? {};

  const { functionality, configCurrency, businessData } = React.useContext(
    MENU_SHOPPINGCART_CONTEXT
  );

  const { btnPrimaryColor: btnColor = "", lblTextColor = "" } =
    businessData.config?.style?.digitalMenu ?? {};

  const filteredSubProducts = subProducts.filter((sp) => sp.isActive === true);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDetailProduct = () => {
    setIsOpen(!isOpen);
  };

  // Promo
  const [stock] = mainStockLocation;

  const promoData = {
    Cantidad: {
      type: "typeCantidad",
      icon: <IconSales2x1 color={btnColor} />,
    },
    Porcentaje: {
      type: "typePorcentaje",
      icon: <IconSales color={btnColor} />,
    },
    ProcentajeCantidad: {
      type: "typePorcentajeCantidad",
      icon: <IconSales color={btnColor} />,
    },
  };

  // Estimate the availability of the promotion
  const availablePromo = estimateAvailabilityOfThePromotion(stock?.promo);
  const [totalOfTheProduct, setTotalOfTheProduct] = React.useState(0);

  return (
    <ContainerGeneral btnColor={btnColor}>
      <ContainerImages image={image} onClick={toggleDetailProduct}>
        <div className="images"></div>
      </ContainerImages>
      <ContainerDetail lblTextColor={lblTextColor} btnColor={btnColor}>
        <div className="detail">
          <h3 onClick={toggleDetailProduct}>
            {name}
            {stock?.promo?.isActive && availablePromo && (
              <span className="sale">
                {promoData[stock?.promo?.type]?.icon}
                <p>{stock?.promo?.name}</p>
              </span>
            )}
          </h3>
          {filteredSubProducts.length > 0 ? (
            filteredSubProducts.map((product, idx) => (
              <div className="subProducts" key={idx}>
                <p>
                  {product.nameShort}{" "}
                  {product.price?.toLocaleString(configCurrency.bcp47, {
                    style: "currency",
                    currency: configCurrency.iso_4217,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </p>
                {product?.locationsStock?.[0]?.promo?.isActive &&
                  estimateAvailabilityOfThePromotion(
                    product?.locationsStock?.[0]?.promo
                  ) &&
                  promoData[product?.locationsStock?.[0]?.promo?.type]?.icon}
              </div>
            ))
          ) : (
            <p>{description}</p>
          )}
        </div>
        <div className="price">
          <h5>
            {stock?.promo?.type == "Porcentaje" && availablePromo && (
              <span>$ {stock?.price}</span>
            )}
            {stock?.promo?.type === "ProcentajeCantidad" &&
              stock?.price !== totalOfTheProduct &&
              availablePromo && <span>$ {stock?.price}</span>}
            {filteredSubProducts.length === 0 &&
              totalOfTheProduct?.toLocaleString(configCurrency.bcp47, {
                style: "currency",
                currency: configCurrency.iso_4217,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
          </h5>
          <div>
            <Btn onClick={toggleDetailProduct} btnColor={btnColor}>
              {functionality ? "Agregar" : "Ver"}
            </Btn>
            <DetailProduct
              product={product}
              subProducts={filteredSubProducts}
              isOpen={isOpen}
              closeModal={toggleDetailProduct}
              totalOfTheProduct={totalOfTheProduct}
              setTotalOfTheProduct={setTotalOfTheProduct}
            />
          </div>
        </div>
      </ContainerDetail>
    </ContainerGeneral>
  );
}

const ContainerGeneral = styled.div`
  width: calc(100% / 2 - 40px);
  min-width: calc(100% / 2 - 40px);
  height: auto;
  margin: 20px;
  border: 1px solid
    ${(props) => (props.btnColor ? props.btnColor : "var(--color-Green)")};
  background-color: var(--color-White);
  border-radius: 25px;
  display: flex;
  @media (max-width: 1023px) {
    width: 100%;
    margin: 5px 0;
  }
`;

const ContainerImages = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  &:hover {
    cursor: pointer;
  }
  .images {
    background-image: ${(props) =>
      props.image === undefined
        ? `url(${restobarIcon})`
        : `url(${props.image})`};
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-Gray-2);
    background-size: cover;
    width: 140px;
    height: 140px;
    border-radius: 20px;
    @media (max-width: 767px) {
      width: 80px;
      height: 80px;
    }
  }
`;

const ContainerDetail = styled.div`
  flex: 3;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 767px) {
    padding: 1rem 0.8rem;
    padding-left: 0;
  }
  .detail {
    margin-bottom: 0.3rem;
    > h3 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      color: ${(props) =>
        props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
      .sale {
        padding: 0.5rem;
        margin-left: 0.3rem;
        background-color: ${(props) =>
          props.btnColor ? props.btnColor : "var(--color-Green)"};
        display: flex;
        align-items: center;
        border-radius: 30px;
        > p {
          font-size: 0.7rem;
          color: var(--color-White);
          padding: 0 0.5rem 0 0;
          margin-top: 1px;
        }
        > svg {
          height: 1rem;
        }
      }
    }
    > p {
      margin-right: 0.3rem;
      font-size: 0.8rem;
      line-height: 1.3;
      color: ${(props) =>
        props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    .subProducts {
      display: flex;
      align-items: center;
      > p {
        margin-right: 0.3rem;
        font-size: 0.8rem;
        line-height: 1.3;
        color: ${(props) =>
          props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      > svg {
        height: 1rem;
      }
    }
  }
  .price {
    font-size: 1rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > h5 {
      display: flex;
      flex-direction: column;
      > span {
        text-decoration: line-through;
        opacity: 0.8;
        font-size: 0.6rem;
        font-weight: 300;
      }
    }
  }
`;

const Btn = styled.div`
  padding: 10px 24px;
  background-color: ${(props) =>
    props.btnColor ? props.btnColor : "var(--color-Green)"};
  color: var(--color-White);
  border-radius: 50px;
  font-size: 0.7rem;
  font-weight: 300;
  &:hover {
    cursor: pointer;
  }
`;
