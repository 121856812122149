import React from "react";

function More({ color }) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    >
    <path
      stroke={color ? color : "#90DA56"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zM12 8v8M8 12h8"
    ></path>
    </svg>
  );
}

export default More;