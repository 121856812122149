import React from "react";

export default function IconSales2x1({ color }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="25"
        fill="none"
        viewBox="0 0 26 25"
      >
        <rect width="26" height="25" fill={color && color} rx="15"></rect>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.833 4.5l2.575 5.217 5.759.841L17 14.617l.983 5.733-5.15-2.708-5.15 2.708.984-5.733L4.5 10.558l5.758-.841L12.833 4.5z"
        ></path>
      </svg>
    );
  }