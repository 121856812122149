import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//
import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";
/*icon */
import IconShoppingCar from "../../assets/images/icons/shopping-car";
import IconLogOut from "../../assets/images/icons/log-out.svg";
import IconMenu from "../../assets/images/icons/menu.svg";
import IconClose from "../../assets/images/icons/closeLight.svg";
import LogoPirpos from "../../assets/images/logoPirpos.svg";

const logo_loggro_restobar =
  "https://s3.amazonaws.com/app.pirpos.com/www/assets/images/restobar/Logo+Loggro+Restobar.svg";

export default function Header({
  session,
  business,
  menuInactive,
  carActive,
  quantity = 0,
  closeModal,
  urlExtension,
  btnColor,
  logOut,
}) {
  const {
    functionality,
    businessData: { urlImage, config, tradeName, alliances },
  } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  const { backgroundColor } =
    config && config.style && config.style.digitalMenu
      ? config.style.digitalMenu
      : "";

  const [showMenu, setShowMenu] = useState(false);
  const handlerShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <ContainerGeneral backgroundColor={backgroundColor}>
      {alliances?.pepsi ? (
        <>
          {tradeName ? (
            <Name>{tradeName}</Name>
          ) : (
            <Logo logo={urlImage ? urlImage : LogoPirpos} />
          )}
        </>
      ) : (
        <Logo logo={urlImage ? urlImage : logo_loggro_restobar} />
      )}
      {/* {console.log("nombre", tradeName)} */}
      <Container>
        <MenuIcon onClick={handlerShowMenu} src={IconMenu} alt="Menú" />
        <Menu show={showMenu} btnColor={btnColor}>
          <img
            onClick={handlerShowMenu}
            className="close"
            src={IconClose}
            alt="Cerrar"
          />
          <Link
            className={!menuInactive ? "active" : null}
            to={`/menu/${business}${urlExtension}`}
          >
            <li>Menú</li>
          </Link>
          {/* <Link to={`/menu/${business}${urlExtension}`}> */}
          <a href="#footer_contact" onClick={handlerShowMenu}>
            <li>Contacto</li>
          </a>
          {session && functionality && (
            <li
              className="logOut"
              onClick={() => {
                logOut();
                handlerShowMenu();
              }}
            >
              <img
                title="Cerrar sesión"
                className="logOut"
                src={IconLogOut}
                alt="logOut"
              />
              <p>Cerrar sesión</p>
            </li>
          )}
        </Menu>
        {functionality && (
          <ContainerBtn color={btnColor}>
            {session ? (
              <Link
                title="Carro de compras"
                className="car"
                to={`/shopping-cart/${business}${urlExtension}`}
              >
                <IconShoppingCar color={carActive && btnColor} />
                {carActive && <p>{quantity}</p>}
              </Link>
            ) : (
              <div
                className="car"
                title="Carro de compras"
                onClick={() => {
                  closeModal();
                }}
              >
                <IconShoppingCar color={carActive && btnColor} />
                {carActive && <p>{quantity}</p>}
              </div>
            )}
            {session && (
              <img
                title="Cerrar sesión"
                className="logOut"
                src={IconLogOut}
                onClick={() => logOut()}
                alt="logOut"
              />
            )}
          </ContainerBtn>
        )}
      </Container>
    </ContainerGeneral>
  );
}

const ContainerGeneral = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100px;
  padding: 20px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-White)"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    position: fixed;
    padding: 10px 20px;
    height: 80px;
    z-index: 99;
    top: 0;
  }
  @media (max-width: 1023px) and (orientation: landscape) {
    position: initial;
  }
`;

const Name = styled.h3`
  color: var(--color-Gray);
  line-height: 1.1;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const Logo = styled.div`
  width: 150px;
  height: 60px;
  background-image: ${(props) => `url(${props.logo})`};
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
`;

const Container = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    justify-content: flex-end;
  }
`;

const MenuIcon = styled.img`
  display: none;
  @media (max-width: 1023px) {
    display: flex;
    width: auto;
  }
`;

const Menu = styled.ul`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0 20px;
  @media (max-width: 1023px) {
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    opacity: ${(props) => (props.show ? "1" : "0")};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20%;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    transform: ${(props) => `translateX(${props.show ? "0" : "-100%"})`};
    transition: 0.4s;
    z-index: 99;
    background-color: var(--color-White);
  }
  .close {
    display: none;
    @media (max-width: 1023px) {
      display: block;
      padding: 0.5rem;
    }
  }
  .active {
    color: ${(props) =>
      props.btnColor ? props.btnColor : "var(--color-Green)"};
  }
  > a {
    color: var(--color-Gray);
    padding: 0.5rem;
    &:hover {
      color: ${(props) =>
        props.btnColor ? props.btnColor : "var(--color-Green)"};
      transition: 0.2s;
    }
  }
  .logOut {
    display: none;
    align-items: center;
    font-weight: 600;
    color: var(--color-Gray);
    @media (max-width: 1023px) {
      display: flex;
    }
    > img {
      margin: 0 0.5rem;
    }
  }
  > a li {
    font-weight: 600;
  }
`;

const ContainerBtn = styled.div`
  display: flex;
  .car,
  .logOut {
    display: flex;
    align-items: center;
    transition: 0.2s;
    padding: 8px;
    border-radius: 5px;
    > p {
      margin-left: 5px;
      color: ${(props) => (props.color ? props.color : "var(--color-Green)")};
    }
  }
  @media (max-width: 1023px) {
    .car,
    .logOut {
      display: none;
    }
  }
  .car:hover,
  .logOut:hover {
    background-color: var(--color-Back);
    cursor: pointer;
  }
  .logOut {
    margin-left: 0.5rem;
  }
`;
