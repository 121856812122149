import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Loading } from "./Loading";

import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";
/*icon */
import iconoClose from "../../assets/images/icons/close.svg";

const ModalRegister = ({ isOpen, closeModal, additionalMessage }) => {
  const {
    setSession: newSession,
    setToken,
    freeToken,
    searchUser,
    setNumber,
    number,
    businessData,
  } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  const {
    backgroundColor = "",
    btnPrimaryColor: btnColor = "",
    lblTextColor = "",
  } = businessData.config?.style?.digitalMenu ?? {};

  /*input handler phone */
  const handlerInputPhone = (e) => {
    setNumber(e.target.value);
  };

  /*input handler code */
  const [code, setCode] = useState("");

  const handlerInputCode = (e) => {
    setCode(e.target.value);
  };

  /*state terms */
  const [terms, setTerms] = useState(false);

  const handlerTerms = () => {
    setTerms(!terms);
  };

  /*state sendding code */
  const [senddingCode, setSenddingCode] = useState(false);
  const [validateCode, setValidateCode] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const sendCode = (e) => {
    if (e.target.dataset.status === "enabled") {
      // Axios
      setLoading(true);

      axios
        .post(
          `${process.env.REACT_APP_CODE_VERIFICATION_BASE_URL}${number}`,
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSenddingCode(true);
            //
            console.log("Code sent");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  // Validating code
  const setSession = (e) => {
    if (e.target.dataset.status === "enabled") {
      //
      setLoading(true);

      axios
        .post(
          `${process.env.REACT_APP_VALIDATE_CODE_BASE_URL}${number}/${code}`,
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            //
            console.log("Code verified");
            // Set session
            await newSession(true);
            // Set token
            await setToken(res.data.token);
            // Get user if this exists
            await searchUser(res.data.token);
            await closeModal();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // It's true if the code is not right
          setValidateCode(true);
          setLoading(false);
        });
    }
  };

  // REACT_APP_FREE_TOKEN_BASE_URL
  const setFreeSession = () => {
    if (freeToken) {
      //
      setLoading(true);

      axios
        .post(
          `${process.env.REACT_APP_FREE_TOKEN_BASE_URL}${number}`,
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            // Set session
            await newSession(true);
            // Set token
            await setToken(res.data.token);
            // Get user if this exists
            await searchUser(res.data.token);
            await closeModal();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <div className={`modal ${isOpen && "modal-open"}`}>
        <ContainerModal
          lblTextColor={lblTextColor}
          backgroundColor={backgroundColor}
        >
          <img
            className="close"
            src={iconoClose}
            alt="cerrar"
            onClick={() => {
              closeModal();
            }}
          />
          {additionalMessage ? (
            <h3>{additionalMessage}</h3>
          ) : (
            <h4>Iniciar/Registrarse</h4>
          )}
          <InputText className="input" lblTextColor={lblTextColor}>
            <label>
              Número telefónico<span>*</span>
            </label>
            <input
              placeholder="ej. 3134560019"
              type="number"
              value={number}
              onChange={handlerInputPhone}
            />
            {number === "" && <span>Campo vacio</span>}
            {number.length < 10 && number !== "" && (
              <span>Mínimo 10 números</span>
            )}
            {number.length > 10 && <span>Máximo 10 números</span>}
          </InputText>
          {senddingCode && (
            <InputText className="input" lblTextColor={lblTextColor}>
              <label>
                Código de verificación<span>*</span>
              </label>
              <input
                placeholder="ej. 3175"
                type="number"
                value={code}
                onChange={handlerInputCode}
              />
              {code === "" && <span>Campo vacio</span>}
              {code.length > 4 && <span>Código máximo de 4 números</span>}
              {validateCode && <span>Código erróneo</span>}
            </InputText>
          )}
          {freeToken ? (
            <p className="note">
              <span>Nota:</span> Por favor ingrese un número de celular válido.
            </p>
          ) : senddingCode ? (
            <p className="note">
              <span>Nota:</span> Se ha enviado un SMS al celular ingresado. Por
              favor digítelo. <span className="resend">Volver a enviar</span>
            </p>
          ) : (
            <p className="note">
              <span>Nota:</span> Por favor ingrese un número de celular válido,
              se enviará un SMS con un código de verificación.
            </p>
          )}
          <Label lblTextColor={lblTextColor}>
            Acepto{" "}
            <span>
              <a
                href="https://s3.amazonaws.com/app.pirpos.com/www/files/TerminosYCondicionesMenuDigital.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Términos y condiciones.
              </a>
            </span>
            <Input type="checkbox" value={terms} onChange={handlerTerms} />
            <Indicator />
          </Label>
          {isLoading ? (
            <Btn btnColor={btnColor}>
              <Loading height="20px" width="20px" color="white" />
            </Btn>
          ) : freeToken ? (
            <Btn
              onClick={
                !terms || number === "" || number.length !== 10
                  ? null
                  : setFreeSession
              } // Free token, set session and token
              btnColor={btnColor}
              className={
                !terms || number === "" || number.length !== 10
                  ? "disabled"
                  : ""
              }
            >
              Entrar
            </Btn>
          ) : senddingCode ? (
            <Btn
              className={
                !terms ||
                number === "" ||
                number.length !== 10 ||
                code === "" ||
                code.length !== 4
                  ? "disabled"
                  : ""
              }
              data-status={
                !terms ||
                number === "" ||
                number.length !== 10 ||
                code === "" ||
                code.length !== 4
                  ? "disabled"
                  : "enabled"
              }
              onClick={setSession} // Validating code, set session and token
              btnColor={btnColor}
            >
              Iniciar
            </Btn>
          ) : (
            <Btn
              className={
                !terms || number === "" || number.length !== 10
                  ? "disabled"
                  : ""
              }
              data-status={
                !terms || number === "" || number.length !== 10
                  ? "disabled"
                  : "enabled"
              }
              onClick={sendCode} // Send verification code to mobile
              btnColor={btnColor}
            >
              Enviar código
            </Btn>
          )}
        </ContainerModal>
      </div>
    </Container>
  );
};

export default ModalRegister;

const Container = styled.div`
  .modal {
    width: 100%;
    height: 100%;
    padding: 2% 0;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: var(--color-Back-Gray);
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  .modal-open {
    display: flex;
  }
`;

const ContainerModal = styled.div`
  width: 450px;
  height: auto;
  max-height: 600px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-Back-Yelow)"};
  border-radius: 1rem;
  padding: 40px 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    min-height: 200px;
  }

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 767px) {
      top: 1rem;
      right: 1rem;
    }
  }

  > h4 {
    font-size: 1.8rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    opacity: 0.85;
    margin: 1.2rem 0;
    text-align: center;
    line-height: 2.2rem;
    @media (max-width: 767px) {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .note {
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    font-size: 0.8rem;
    margin: 5px 0;
    > span {
      font-weight: 700;
    }
    .resend:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .disabled {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

const InputText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100px;
  > label {
    font-size: 0.8rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    span {
      color: var(--color-Orange);
    }
  }
  > input {
    border-color: var(--color-Gray);
    border: 1px solid var(--color-Gray);
    border-radius: 8px;
    padding: 0.75rem 0.75rem;
    font-size: 0.8rem;
    margin: 5px 0;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: var(--color-Place-Holder);
    }
  }
  > span {
    color: var(--color-Orange);
    font-size: 0.8rem;
  }
`;

const Btn = styled.div`
  padding: 1rem;
  width: 100%;
  height: 40px;
  margin-top: 1rem;
  background-color: ${(props) =>
    props.btnColor ? props.btnColor : "var(--color-Green)"};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-White);
  @media (max-width: 767px) {
    font-size: 0.7rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 4px 20px;
  padding-left: 5px;
  font-size: 0.8rem;
  color: ${(props) =>
    props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
  > span {
    font-weight: 700;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    > a {
      color: inherit;
    }
  }
`;

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1px;
  left: -1.5em;
  border: 1px solid var(--color-Gray);
  background-color: var(--color-White);
  border-radius: 0.2em;

  ${Input}:not(:disabled):checked & {
    background: #e7e7e7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + & {
    background-color: var(--color-Gray);
    border-color: var(--color-Gray);
  }

  ${Input}:checked + &::after {
    display: block;
    top: 1px;
    left: 4px;
    width: 25%;
    height: 50%;
    border: solid var(--color-White);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
