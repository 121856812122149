import React from "react";

function Location({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color ? color : "#585858"}
        fillRule="evenodd"
        d="M12 2c3.87 0 7 3.13 7 7 0 5.25-7 13-7 13S5 14.25 5 9c0-3.87 3.13-7 7-7zM7 9c0 2.85 2.92 7.21 5 9.88 2.12-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5S7 6.24 7 9zm5 2.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Location;