import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { MainProductCounter } from "../shared/Counter";

import {
  findProductAndSubProductInMenu,
  getCurrentStock,
  updateProductInShoppingCart,
} from "./validateShoppingCar";

/*icons */
import iconoDelete from "../../assets/images/icons/delete.svg";
import IconSales from "../../assets/images/icons/Sales";
import IconSales2x1 from "../../assets/images/icons/Sales2x1";
import restobarIcon from "../../assets/images/default-product-image.svg";

import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";

export default function ProductCar({
  product: productInShoppingCart,
  product: {
    image,
    name,
    id_shoppingCart,
    subTotal = 0,
    quantity = 0,
    notes,
    extras = [],
    combo = {},
  },
  changeAmount,
  setChangeAmount,
}) {
  const { menu, shoppingCart, setShoppingCart, configCurrency, businessData } =
    React.useContext(MENU_SHOPPINGCART_CONTEXT);
  const { id: businessId } = useParams();

  const { btnPrimaryColor: btnColor = "", lblTextColor = "" } =
    businessData.config?.style?.digitalMenu ?? {};

  const [productInMenu, setProductInMenu] = React.useState({});
  const [stock, setStock] = React.useState({});

  React.useEffect(() => {
    const { productInMenu, subProductInMenu } = findProductAndSubProductInMenu(
      menu,
      productInShoppingCart
    );

    const currentStock = getCurrentStock(
      businessId,
      productInMenu,
      subProductInMenu
    );

    setProductInMenu(productInMenu);
    setStock(currentStock);
  }, [menu]);

  const changeQuantityOfTheMainProduct = (newQuantity) => {
    const updatedShoppingCart = updateProductInShoppingCart(
      stock,
      newQuantity,
      shoppingCart,
      productInShoppingCart,
      productInMenu
    );

    setShoppingCart(updatedShoppingCart);

    if (!changeAmount) {
      setChangeAmount(true);
    }
  };

  const deleteProduct = () => {
    delete shoppingCart[id_shoppingCart];

    setShoppingCart({
      ...shoppingCart,
    });

    if (!changeAmount) {
      setChangeAmount(true);
    }
  };

  const promoData = {
    Cantidad: {
      type: "typeCantidad",
      icon: <IconSales2x1 color={btnColor} />,
    },
    Porcentaje: {
      type: "typePorcentaje",
      icon: <IconSales color={btnColor} />,
    },
    ProcentajeCantidad: {
      type: "typePorcentajeCantidad",
      icon: <IconSales color={btnColor} />,
    },
  };

  return (
    <ContainerGeneral btnColor={btnColor}>
      <ContainerImages image={image}>
        <div className="images"></div>
      </ContainerImages>
      <ContainerDetail lblTextColor={lblTextColor}>
        <div className="detail">
          <h3>{name}</h3>
          {productInShoppingCart?.promo &&
            promoData[productInShoppingCart?.promo?.type]?.icon}
          <p>{notes}</p>
          {screen.width > 600 &&
            Object.values(combo).map((e, idx) => {
              if (e.qty) {
                return (
                  <p key={idx} className="detail_options">
                    {e.optionName && `${e.optionName} - `}
                    {e.name} x {e.qty}
                  </p>
                );
              }
            })}
          {extras.map((extra, idx) => (
            <p key={idx} className="detail_options">
              {extra.name} x {`${extra.qty} - `}
              {extra.total.toLocaleString(configCurrency.bcp47, {
                style: "currency",
                currency: configCurrency.iso_4217,
              })}
            </p>
          ))}
        </div>
        <div className="price">
          <h5>
            {subTotal?.toLocaleString(configCurrency.bcp47, {
              style: "currency",
              currency: configCurrency.iso_4217,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </h5>
          <div className="containerCount">
            <MainProductCounter
              initialValue={quantity}
              updateValue={changeQuantityOfTheMainProduct}
              withoutBorder={true}
            />
            <img
              className="delete"
              src={iconoDelete}
              alt="delete"
              onClick={deleteProduct}
            />
          </div>
        </div>
      </ContainerDetail>
    </ContainerGeneral>
  );
}

const ContainerGeneral = styled.div`
  width: calc(100% - 20px);
  max-width: 500px;
  height: auto;
  margin: 5px 10px;
  border: 1px solid
    ${(props) => (props.btnColor ? props.btnColor : "var(--color-Green)")};
  border-radius: 25px;
  display: flex;
  background-color: var(--color-White);
  @media (max-width: 1023px) {
    width: 100%;
    margin: 10px 0;
    max-width: none;
  }
`;

const ContainerImages = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
  .images {
    background-image: ${(props) =>
      props.image === undefined
        ? `url(${restobarIcon})`
        : `url(${props.image})`};
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-Gray-2);
    background-size: cover;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    @media (max-width: 767px) {
      width: 80px;
      height: 80px;
    }
  }
`;

const ContainerDetail = styled.div`
  flex: 3;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
  .detail {
    > h3 {
      font-size: 1rem;
      color: ${(props) =>
        props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      font-weight: 600;
    }
    > p {
      font-size: 0.8rem;
      line-height: 1.3;
      color: ${(props) =>
        props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    .detail_options {
      font-size: 0.65rem;
    }
  }
  .price {
    font-size: 1.2rem;
    color: ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Gray)"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: 374px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .containerCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .delete {
      margin-left: 1rem;
      @media (max-width: 767px) {
        margin-left: 0.5rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
