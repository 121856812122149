// Estimate the availability of the promotion
const estimateAvailabilityOfThePromotion = (promo) => {
  // First validation
  if (
    !promo?.isActive ||
    !promo?.forDigitalMenu ||
    typeof promo?.dateSettings !== "object"
  )
    return false;

  const currentDate = new Date();

  // dateSettings.date
  const { start, final: end } = promo?.dateSettings?.date ?? {};
  const startDate = start ? new Date(start) : currentDate;
  const endDate = end ? new Date(end) : currentDate;
  if (startDate > currentDate || currentDate > endDate) return false;

  // dateSettings.days
  const dayOptions = ["D", "L", "M", "X", "J", "V", "S"];
  const availableDays = promo?.dateSettings?.days ?? [];
  const currentDay = dayOptions[currentDate.getDay()];
  if (
    availableDays.length > 0 &&
    !availableDays.some((day) => day === currentDay)
  )
    return false;

  // dateSettings.happyHours
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const { hour: startHour = 0, minute: startMinute = 0 } =
    promo?.dateSettings?.happyHours?.hours?.hourStart ?? {};
  const { hour: endHour = 23, minute: endMinute = 59 } =
    promo?.dateSettings?.happyHours?.hours?.hourEnd ?? {};

  const { isHappyHours } = promo?.dateSettings?.happyHours?.isHappyHour;
  if (isHappyHours) {
    if (
      (currentHour === startHour && currentMinute < startMinute) ||
      currentHour < startHour ||
      (currentHour === endHour && currentMinute > endMinute) ||
      currentHour > endHour
    )
      return false;
  }

  //? return false by default
  return true;
};

// Calculate quantity of the main product to pay
const calculateQuantityToPay = (qtySelected, promo, availablePromo) => {
  if (promo?.type === "Cantidad" && availablePromo) {
    let { payAmount = 1, takeAmount = 1 } = promo?.data?.typeCantidad ?? {};

    payAmount <= 0 && (payAmount = 1);
    takeAmount <= 0 && (takeAmount = 1);

    return Math.ceil((qtySelected / takeAmount) * payAmount);
  } else {
    return qtySelected;
  }
};

// Calculate price of the main product to pay
const calculatePriceToPay = (
  qtySelected,
  promo,
  originalPrice,
  availablePromo
) => {
  if (promo?.type === "Porcentaje" && availablePromo) {
    const { value: promoPercentage = 0 } = promo?.data?.typePorcentaje ?? {};
    return (originalPrice * (100 - promoPercentage)) / 100;
  } else if (promo?.type === "ProcentajeCantidad" && availablePromo) {
    const { values: cantidadVsPercentages = [] } =
      promo?.data?.typePorcentajeCantidad ?? {};

    // Apply the first matching element
    const [promoApplied] = cantidadVsPercentages.filter(
      //? qtyMin && qtyMax can be undefined ?
      (e) => e.qtyMin <= qtySelected && qtySelected <= e.qtyMax
    );

    const promoPercentage = promoApplied?.value ?? 0;

    return (originalPrice * (100 - promoPercentage)) / 100;
  } else {
    return originalPrice;
  }
};

export {
  estimateAvailabilityOfThePromotion,
  calculateQuantityToPay,
  calculatePriceToPay,
};
