import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useParams } from "react-router-dom";
import axios from "axios";

/* Components */
import { MENU_SHOPPINGCART_CONTEXT } from "../context/MenuShoppingCartProvider";
import Header from "../components/shared/Header";
import NavBar from "../components/menu/NavBar";
import AllianceBanner from "../components/menu/AllianceBanner";
import CategoryProducts from "../components/menu/CategoryProducts";
import Footer from "../components/shared/Footer";
import CarMobile from "../components/menu/CarMobile";

/*component modal */
import ModalRegister from "../components/shared/ModalRegister";

/* Assets */
import iso3166 from "../assets/iso3166-1_alfa2.json";

export default function Menu() {
  // Menu && ShoppingCart context
  const {
    session,
    setFreeToken,
    functionality,
    setFunctionality,
    // shoppingCart,
    setShoppingCart,
    categorySelected,
    setCategorySelected,
    quantity,
    subTotal,
    businessData,
    setBusinessData,
    setMenu,
    configCurrency,
    setConfigCurrency,
    setConfigDeliveryCost,
    setConfigPayU,
    urlExtension,
    logOut,
  } = React.useContext(MENU_SHOPPINGCART_CONTEXT);

  // Get business Id & current category selected in menu's Url
  const { id, category } = useParams();
  const beforeBusinessInLS = window.localStorage.getItem("business");
  const beforeBusinessInUrl = beforeBusinessInLS
    ? JSON.parse(beforeBusinessInLS)
    : "";

  if (id !== beforeBusinessInUrl) {
    setShoppingCart({});
    window.localStorage.setItem("business", JSON.stringify(id));
  }

  //
  const [categories, setCategories] = React.useState([]);

  // Validation when the business doesn't have a configured menu
  const [menuBusiness, setMenuBusiness] = React.useState(true);

  /* ---------- Query of business && menu to the API  ---------- */
  React.useEffect(() => {
    // Business info
    axios
      .get(
        `${process.env.REACT_APP_BUSINESS_DATA_BASE_URL}/business/${id}/public`
      )
      .then((res) => {
        // Business data
        setBusinessData(res.data);

        // Currency
        if (res.data?.cityDetail?.countryCode) {
          setConfigCurrency({
            countryCode: res.data.cityDetail.countryCode,
            bcp47: iso3166[res.data.cityDetail.countryCode.toUpperCase()][
              "bcp47"
            ]
              ? iso3166[res.data.cityDetail.countryCode.toUpperCase()]["bcp47"]
              : "es-CO",
            iso_4217: iso3166[res.data.cityDetail.countryCode.toUpperCase()][
              "iso_4217"
            ]
              ? iso3166[res.data.cityDetail.countryCode.toUpperCase()][
                  "iso_4217"
                ]
              : "COP",
          });
        }

        // DeliveryCost
        if (res.data?.config?.externalIntegration?.domiPirPos?.deliveryCost) {
          setConfigDeliveryCost(
            res.data.config.externalIntegration.domiPirPos.deliveryCost
          );
        }

        // PayU
        if (
          res.data?.config?.externalIntegration?.payU?.contactless?.isActive
        ) {
          setConfigPayU(true);
        } else {
          setConfigPayU(false);
        }

        // functionality takeOrders or visualization
        // If visualization then the user only can watch the menu
        if (res.data?.config?.style?.functionality === "visualization") {
          setFunctionality(false);
        } else {
          setFunctionality(true);
        }

        // Free token
        if (!res.data?.config?.style?.isPaid) {
          setFreeToken(true);
        } else {
          setFreeToken(false);
        }
      })
      .catch((error) => console.log(error));

    // Menu
    axios
      .get(`${process.env.REACT_APP_MENU_DATA_BASE_URL}/business/${id}/menu`)
      .then((res) => {
        // Validation when the business doesn't have a configured menu
        res.data === "" && setMenuBusiness(false);

        if (res.data?.[0]?.products && res.data[0].category.name) {
          setCategories(res.data);

          // State to validate shopping cart (shopping cart view)
          setMenu(res.data);

          // Initial Category
          if (!categorySelected.anySelected || !category) {
            setCategorySelected({
              ...categorySelected,
              anySelected: false,
              selected: res.data[0],
            });
          } else {
            setCategorySelected({
              ...categorySelected,
              selected:
                res.data.find((i) => i.category.name === category) ??
                res.data[0],
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }, []);

  // Set current category
  function categoryClick(e) {
    setCategorySelected({
      ...categorySelected,
      anySelected: true,
      selected: categories[e.target.dataset.index],
    });
  }

  /* ---------- Show modal start/register ---------- */
  const [isOpenRegister, setIsOpenRegister] = React.useState(false); // Login, access to shopping cart

  const toggleModalRegister = () => {
    setIsOpenRegister(!isOpenRegister);
  };

  /* ---------- Status of cart ---------- */
  // Cart in desktop screen
  let carActive = false;
  if (quantity > 0) {
    carActive = true;
  } else {
    carActive = false;
  }

  // Image banner
  const BANNER_IMAGE =
    "https://s3.amazonaws.com/menu.pirpos.com/build/banners/Banner-Pepsi.png";

  return (
    <ContainerGeneral>
      <GlobalStyle
        backgroundColor={
          businessData.config?.style?.digitalMenu?.backgroundColor ?? ""
        }
      />
      <div className="container">
        <Header
          session={session}
          business={id}
          carActive={carActive}
          quantity={quantity}
          closeModal={toggleModalRegister}
          urlExtension={urlExtension}
          btnColor={
            businessData.config?.style?.digitalMenu?.btnPrimaryColor ?? ""
          }
          logOut={logOut}
        />
        <NavBar
          session={session}
          business={id}
          categories={categories}
          categoryClick={categoryClick}
          carActive={carActive}
          quantity={quantity}
          categorySelected={categorySelected}
          closeModal={toggleModalRegister}
          urlExtension={urlExtension}
          btnColor={
            businessData.config?.style?.digitalMenu?.btnPrimaryColor ?? ""
          }
          backgroundColor={
            businessData.config?.style?.digitalMenu?.backgroundColor ?? ""
          }
          lblTextColor={
            businessData.config?.style?.digitalMenu?.lblTextColor ?? ""
          }
          logOut={logOut}
        />
        {businessData.alliances?.pepsi && (
          <AllianceBanner
            btnColor={
              businessData.config?.style?.digitalMenu?.btnPrimaryColor ?? ""
            }
            imageSrc={BANNER_IMAGE}
          />
        )}
        <CategoryProducts
          products={categorySelected.selected?.products}
          menuBusiness={menuBusiness}
          btnColor={
            businessData.config?.style?.digitalMenu?.btnPrimaryColor ?? ""
          }
          stateBanner={businessData.alliances?.pepsi ? true : false}
        />
        <Footer />
      </div>
      {functionality && (
        <CarMobile
          session={session}
          business={id}
          quantity={quantity}
          total={subTotal}
          configCurrency={configCurrency}
          closeModal={toggleModalRegister}
          urlExtension={urlExtension}
          btnColor={
            businessData.config?.style?.digitalMenu?.btnPrimaryColor ?? ""
          }
          backgroundColor={
            businessData.config?.style?.digitalMenu?.backgroundColor ?? ""
          }
        />
      )}
      <ModalRegister isOpen={isOpenRegister} closeModal={toggleModalRegister} />
    </ContainerGeneral>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "var(--color-White)"};
  }
`;

const ContainerGeneral = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
