import React from "react";

export default function IconSales({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <rect width="24" height="24" y="0.5" fill={color && color} rx="12"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.375 8.125l-8.75 8.75M8.563 10.625a1.563 1.563 0 100-3.125 1.563 1.563 0 000 3.125zM15.438 17.5a1.563 1.563 0 100-3.125 1.563 1.563 0 000 3.125z"
      ></path>
    </svg>
  );
}