import React from "react";
import { useDebouncedValue, useInterval } from "@mantine/hooks";
import styled from "styled-components";

import { MENU_SHOPPINGCART_CONTEXT } from "../../context/MenuShoppingCartProvider";

import IconLess from "../../assets/images/icons/Less";
import IconMore from "../../assets/images/icons/More";

const Btn = ({ children, onClick, className }) => {
  const interval = useInterval(() => onClick(), 250);

  return (
    <div
      onClick={onClick}
      onMouseDown={() => interval.start()}
      onMouseUp={() => interval.stop()}
      onMouseLeave={() => interval.stop()}
      className={className}
    >
      {children}
    </div>
  );
};

const MainCounter = ({
  lblTextColor,
  innerValue,
  handleLess,
  handleMore,
  withoutBorder,
}) => {
  return (
    <Count lblTextColor={withoutBorder ? "transparent" : lblTextColor}>
      <Btn
        onClick={innerValue > 1 ? handleLess : null}
        className={innerValue === 1 ? "hidden" : null}
      >
        <IconLess color={lblTextColor} />
      </Btn>
      <p>{innerValue}</p>
      <Btn onClick={handleMore}>
        <IconMore color={lblTextColor} />
      </Btn>
    </Count>
  );
};

const AdditionalCounter = ({
  lblTextColor,
  innerValue,
  handleLess,
  handleMore,
}) => {
  return (
    <>
      <Btn
        onClick={innerValue > 1 ? handleLess : null}
        className={innerValue === 1 ? "hidden" : null}
      >
        <IconLess color={lblTextColor} />
      </Btn>
      <p>{innerValue}</p>
      <Btn onClick={handleMore}>
        <IconMore color={lblTextColor} />
      </Btn>
    </>
  );
};

const CounterHOC = (Component) => {
  const Counter = ({
    initialValue,
    updateValue,
    min = 1,
    max = Number.MAX_SAFE_INTEGER,
    withoutBorder,
  }) => {
    const [innerValue, setInnerValue] = React.useState(initialValue);
    const [dampedValue] = useDebouncedValue(innerValue, 250);

    const { businessData } = React.useContext(MENU_SHOPPINGCART_CONTEXT);
    const { lblTextColor = "" } = businessData.config?.style?.digitalMenu ?? {};

    const handleLess = () => {
      setInnerValue((q) => Math.max(q - 1, min));
    };

    const handleMore = () => {
      setInnerValue((q) => Math.min(q + 1, max));
    };

    React.useEffect(() => {
      updateValue(dampedValue);
    }, [dampedValue]);

    return (
      <Component
        lblTextColor={lblTextColor}
        innerValue={innerValue}
        handleLess={handleLess}
        handleMore={handleMore}
        withoutBorder={withoutBorder}
      />
    );
  };

  return Counter;
};

const MainProductCounter = CounterHOC(MainCounter);
const AdditionalProductCounter = CounterHOC(AdditionalCounter);

export { MainProductCounter, AdditionalProductCounter };

const Count = styled.div`
  width: 100px;
  height: 40px;
  background-color: var(--color-White);
  border: 1px solid
    ${(props) =>
      props.lblTextColor ? props.lblTextColor : "var(--color-Green)"};
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    > svg:hover {
      cursor: pointer;
    }
  }
  > p {
    text-align: center;
    font-size: 0.9rem;
  }
  .hidden {
    opacity: 0;
    & svg:hover {
      cursor: auto;
    }
  }
`;
